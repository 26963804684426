import { useMutation } from '@apollo/client';
import React, {
  createContext, useCallback, useEffect, useState,
} from 'react';
import * as Keychain from 'react-native-keychain';
import { Platform } from 'react-native';
import jwt_decode from 'jwt-decode';
import { RefreshTokenMutation } from '../gql/user/mutation';
import { ROLE_NAMES } from '../utils/constant';

const AuthContext = createContext();
const { Provider } = AuthContext;

function AuthProvider({ children }) {
  const [refreshToken, { data, loading, error }] = useMutation(RefreshTokenMutation);

  const [authState, setAuthState] = useState({
    email: null,
    username: null,
    accessToken: null,
    refreshToken: null,
    authenticated: false,
    role: null,
  });

  const logout = async () => {
    if (Platform.OS === 'web') {
      localStorage.setItem('email', null);
      localStorage.setItem('accessToken', null);
      localStorage.setItem('refreshToken', null);
      localStorage.setItem('username', null);
      localStorage.setItem('role', null);
    } else {
      await Keychain.resetGenericPassword();
    }
    setAuthState({
      email: null,
      username: null,
      role: null,
      accessToken: null,
      refreshToken: null,
      authenticated: false,
    });
  };

  const getAccessToken = () => authState.accessToken;

  const isSuperAdmin = authState.role === ROLE_NAMES.SUPERADMIN;

  const loadJWT = useCallback(async () => {
    try {
      // Grab JWT token from localStorage or secure mobile storage
      let jwt;
      let isAccessTokenExpired = false;
      if (Platform.OS === 'web') {
        // set jwt object
        jwt = {
          accessToken: localStorage.getItem('accessToken'),
          refreshToken: localStorage.getItem('refreshToken'),
          username: localStorage.getItem('username'),
          role: localStorage.getItem('role'),
          email: localStorage.getItem('email'),
        };

        // verify token expiration
        if (jwt.accessToken && jwt_decode(jwt.accessToken).exp < Date.now() / 1000) {
          isAccessTokenExpired = true;
          console.warn('Access token expired, trying to use refresh token...');
          if (jwt.refreshToken && jwt_decode(jwt.refreshToken).exp < Date.now() / 1000) {
            throw new Error('Refresh token expired');
          }
          refreshToken({ variables: { refreshToken: jwt.refreshToken } });
        }
      } else {
        const value = await Keychain.getGenericPassword();
        jwt = JSON.parse(value.password);
      }

      if (!isAccessTokenExpired) {
        setAuthState({
          email: jwt.email || null,
          accessToken: jwt.accessToken || null,
          refreshToken: jwt.refreshToken || null,
          authenticated: jwt.accessToken !== null,
          username: jwt.username || null,
          role: jwt.role || null,
        });
      }
    } catch (error) {
      console.log(`Error: ${error.message}`);
      setAuthState({
        email: null,
        accessToken: null,
        refreshToken: null,
        authenticated: false,
        role: null,
      });
    }
  }, []);

  // Explicit useEffect for refreshToken
  useEffect(() => {
    const asyncSetToken = async () => {
      const authState = {
        email: data.refreshToken.user.email,
        accessToken: data.refreshToken.accessToken,
        refreshToken: data.refreshToken.refreshToken,
        authenticated: true,
        username: `${data.refreshToken.user.firstName} ${data.refreshToken.user.lastName}`,
        role: data.refreshToken.user.role,
      };
      if (Platform.OS === 'web') {
        localStorage.setItem('email', data.refreshToken.user.email);
        localStorage.setItem('accessToken', data.refreshToken.accessToken);
        localStorage.setItem('refreshToken', data.refreshToken.refreshToken);
        localStorage.setItem(
          'username',
          `${data.refreshToken.user.firstName} ${data.refreshToken.user.lastName}`,
        );
        localStorage.setItem('role', data.refreshToken.user.role);
        setAuthState(authState);
      } else {
        setAuthState(authState);
        await Keychain.setGenericPassword('token', JSON.stringify(data.refreshToken));
      }
      console.warn('Tokens refreshed successfully.');
    };

    if (data?.refreshToken) asyncSetToken();
  }, [data]);

  return (
    <Provider
      value={{
        authState,
        getAccessToken,
        setAuthState,
        logout,
        loadJWT,
        isSuperAdmin,
      }}
    >
      {children}
    </Provider>
  );
}

export { AuthContext, AuthProvider };
