import * as React from 'react';
import { BottomNavigation } from 'react-native-paper';
import EventInfoTab from './EventInfoTab';
import EventParticipantsTab from './EventParticipantsTab';
// import InfoTab from './InfoTab';
// import DocumentTab from './DocumentTab';

function EventTabs({ event, disabled = false }) {
  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    {
      key: 'info',
      title: 'Infos',
      focusedIcon: 'notebook-edit',
      unfocusedIcon: 'notebook-edit-outline',
    },
    {
      key: 'participants',
      title: 'Participants',
      focusedIcon: 'account-group',
      unfocusedIcon: 'account-group-outline',
    },
    {
      key: 'results',
      title: 'Resultats',
      focusedIcon: 'scoreboard',
      unfocusedIcon: 'scoreboard-outline',
    },
  ]);

  const renderScene = BottomNavigation.SceneMap({
    info: () => <EventInfoTab event={event} disabled={disabled} />,
    participants: () => <EventParticipantsTab event={event} disabled={disabled} />,
    results: () => <></>,
  });

  return (
    <BottomNavigation
      navigationState={{ index, routes }}
      onIndexChange={setIndex}
      renderScene={renderScene}
    />
  );
}

export default EventTabs;
