import React, { useContext, useState } from 'react';
import {
  Appbar, IconButton, List, ProgressBar,
} from 'react-native-paper';
import { useMutation, useQuery } from '@apollo/client';
import { NativeBaseProvider, ScrollView, View } from 'native-base';
import { useToast } from 'react-native-toast-notifications';
import { styles } from '../Common.style';
import { FindMyAnnouncements } from '../../gql/announcement/query';
import { ModalContext } from '../../context/ModalContext';
import AnnouncementModal from '../../modals/ManageAnnouncement/CreateAnnouncementModal';
import UsersOnAnnouncementModal from '../../modals/ManageAnnouncement/DisplayUsersOnAnnouncementModal';
import { DeleteAnnouncement } from '../../gql/announcement/mutation';

function ManageAnnouncements() {
  const toast = useToast();
  const {
    displayCreateAnnouncementModal,
    displayUsersOnAnnouncementModal,
    toggleCreateAnnouncementModal,
    toggleUsersOnAnnouncementModal,
  } = useContext(ModalContext);

  const { data, loading, refetch } = useQuery(FindMyAnnouncements);
  const [deleteAnnouncement, { error: deleteError }] = useMutation(DeleteAnnouncement, {
    onCompleted: (_data) => {
      toast.show(_data.deleteAnnouncement.message);
      refetch();
    },
  });

  const [announcementId, setAnnouncementId] = useState(undefined);

  if (deleteError) toast.show(deleteError.message);

  return (
    <>
      {displayCreateAnnouncementModal && <AnnouncementModal />}
      {displayUsersOnAnnouncementModal && <UsersOnAnnouncementModal announcementId={announcementId} />}
      <View style={styles.container}>
        {loading && <ProgressBar />}
        <Appbar.Header>
          <Appbar.Content title="Liste des annonces" />
          <Appbar.Action
            icon="message-plus"
            onPress={toggleCreateAnnouncementModal}
          />
        </Appbar.Header>
        <ScrollView>
          {data?.findMyAnnouncements?.map((d) => (
            <List.Item
              key={d.id}
              title={`${d.title}`}
              description={d.message}
              left={(props) => <List.Icon {...props} icon="bullhorn-variant" />}
              right={(props) => (
                <>
                  <IconButton
                    {...props}
                    icon="eye"
                    onPress={() => {
                      toggleUsersOnAnnouncementModal();
                      setAnnouncementId(d.id);
                    }}
                  />
                  <IconButton
                    {...props}
                    icon="delete"
                    onPress={() => deleteAnnouncement(
                      {
                        variables: { deleteAnnouncementId: d.id },
                      },
                    )}
                  />
                </>
              )}
            />
          ))}
        </ScrollView>
      </View>
    </>
  );
}

export default function ManageAnnouncementsScreen() {
  return (
    <NativeBaseProvider>
      <ManageAnnouncements />
    </NativeBaseProvider>
  );
}
