import React, { useContext } from 'react';
import {
  Card, ProgressBar, IconButton, Text, Chip, Badge,
} from 'react-native-paper';
import {
  NativeBaseProvider, View,
} from 'native-base';
import { launchImageLibrary } from 'react-native-image-picker';
import { useMutation, useQuery } from '@apollo/client';
import { useRoute } from '@react-navigation/native';
import { AuthContext } from '../../context/AuthContext';
import { FindOneUserQuery } from '../../gql/user/query';
import { styles } from '../Common.style';
import ProfileTabs from './ProfileTabs';
import { UpdateProfileMutation } from '../../gql/profile/mutation';
import { computeCategorie, computeWeightBasedOnCategorie } from '../../utils/utils';
import { ModalContext } from '../../context/ModalContext';
import WeightTrackerModal from '../../modals/ManageWeights';
import InformationModal from '../../modals/Information/InformationModal';
import ConfirmationModal from '../../modals/Confirmation/ConfirmationModal';
import { PromoteOneUserToCompetitor, UpdateOneUser } from '../../gql/user/mutation';
import { ROLE_NAMES } from '../../utils/constant';

function Profile() {
  const route = useRoute();
  const { authState } = useContext(AuthContext);
  const {
    displayWeightModal,
    toggleWeightModal,
    displayInformationModal,
    toggleInformationModal,
    displayConfirmationModal,
    toggleConfirmationModal,
  } = useContext(ModalContext);

  const {
    data: oneUserQuery,
    loading: oneUserQueryLoading,
    error: oneUserQueryError,
    refetch,
  } = useQuery(FindOneUserQuery, {
    variables: {
      email: route.params?.email ?? authState.email,
    },
  });
  const user = oneUserQuery?.findOneUser;

  const [
    updateProfileForUser,
  ] = useMutation(UpdateProfileMutation);

  const [updateOneUser] = useMutation(UpdateOneUser);

  const [promoteOneUserToCompetitor] = useMutation(PromoteOneUserToCompetitor);

  const saveProfilePictureCallback = async ({ didCancel, errorCode, assets }) => {
    if (!didCancel && !errorCode && assets.length > 0) {
      if (authState.role === ROLE_NAMES.SUPERADMIN) {
        await updateOneUser({
          variables: {
            updateOneUserId: user.id,
            profile: {
              picture: assets[0].uri,
            },
          },
        });
      } else {
      // upload asset using gql
        await updateProfileForUser({
          variables: {
            picture: assets[0].uri,
          },
        });
      }
      refetch();
    }
  };

  // Computed values
  const categories = computeCategorie(new Date(user?.profile?.dateOfBirth));
  const isSuperAdmin = authState.role === 'SUPERADMIN';
  const isMyProfile = authState.email === user?.email;

  return (
    <View style={styles.container}>
      {oneUserQueryLoading && <ProgressBar />}
      {oneUserQueryError && (
      <View style={styles.Middle}>
        <Text>{oneUserQueryError.message}</Text>
      </View>
      )}
      {user ? (
        <>
          {displayWeightModal.display && <WeightTrackerModal />}
          {displayInformationModal.display && <InformationModal />}
          {displayConfirmationModal.display && <ConfirmationModal />}
          <Card>
            <Card.Cover
              style={{ alignItems: 'center', backgroundColor: 'transparent' }}
              source={{ uri: user.profile?.picture ?? '', width: '138px', height: '177px' }}
            />
            <Card.Actions>
              {user.status === 'COMPETITOR' && <IconButton icon="karate" />}
              {categories?.map((category) => (<Chip>{category}</Chip>))}
              {user.profile?.weights.length > 0 ? (
                <Chip>
                  {`${computeWeightBasedOnCategorie(
                    user.profile?.weights[user?.profile?.weights?.length - 1].value,
                    user.profile?.gender,
                    categories[0],
                  )}kg`}
                </Chip>
              ) : null}
              <View>
                <Badge style={{ position: 'absolute', top: 5, right: 5 }}>+</Badge>
                <IconButton
                  icon="weight-kilogram"
                  size={20}
                  onPress={() => (isMyProfile ? toggleWeightModal() : toggleWeightModal({ profileId: user.profile.id }))}
                />
              </View>
              {isSuperAdmin && authState.email !== user.email && user.status === 'REVIEW_PENDING'
                ? (
                  <IconButton
                    icon="checkbox-marked-circle-plus-outline"
                    size={20}
                    onPress={() => toggleConfirmationModal({
                      title: 'Confirmation',
                      message: 'Voulez-vous vraiment valider l\'utilisateur ? En faisant cela, l\'utilisateur deviendra un compétiteur et pourra s\'inscrire aux compétitions.',
                      handleSubmit: async () => {
                        await promoteOneUserToCompetitor({
                          variables: {
                            email: user.email,
                          },
                          refetchQueries: [
                            FindOneUserQuery, // DocumentNode object parsed with gql
                            'findOneUser', // Query name
                          ],
                        });
                      },
                    })}
                  />
                ) : null}
              {authState.email === user.email && user.status !== 'COMPETITOR' ? (
                <IconButton
                  icon={user.status === 'REVIEW_PENDING' ? 'progress-clock' : 'message-alert'}
                  size={20}
                  onPress={() => toggleInformationModal({
                    title: 'Information importante',
                    message: user.status === 'REVIEW_PENDING'
                      ? 'Tous les documents sont présents, une notification a été addressée aux administrateurs afin de valider leurs conformités.'
                      : `
Afin de s\'inscrire aux compétitions, il est nécessaire d\'ajouter l\'ensemble des documents requis depuis l\'onglet "Documents"
Un administrateur vérifiera ensuite que tous les documents sont conformes.
                  `,
                  })}
                />
              ) : null}
              <IconButton
                icon="image-edit"
                size={20}
                onPress={() => launchImageLibrary(
                  {
                    mediaType: 'photo',
                    maxWidth: '200px',
                    maxHeight: '200px',
                    includeBase64: true,
                  },
                  saveProfilePictureCallback,
                )}
              />
            </Card.Actions>
          </Card>
          <ProfileTabs user={user} disabled={!isSuperAdmin} />
        </>
      ) : null}
    </View>
  );
}

export default function ProfileScreen() {
  return (
    <NativeBaseProvider>
      <Profile />
    </NativeBaseProvider>
  );
}
