import React from 'react-native';
import { Formik } from 'formik';
import {
  FormControl, Input, NativeBaseProvider, TextArea,
} from 'native-base';
import {
  Button, Dialog, Portal, Text,
} from 'react-native-paper';
import { useContext } from 'react';
import { DatePickerInput } from 'react-native-paper-dates';
import { useMutation } from '@apollo/client';
import { styles } from '../../screens/Common.style';
import { ModalContext } from '../../context/ModalContext';
import { CreateAnnouncement } from '../../gql/announcement/mutation';
import { announcementSchema } from '../../utils/yupSchema';
import { FindMyAnnouncements } from '../../gql/announcement/query';

function Announcement() {
  const {
    displayCreateAnnouncementModal,
    toggleCreateAnnouncementModal,
  } = useContext(ModalContext);
  const [createAnnouncement] = useMutation(CreateAnnouncement);

  return (
    <Formik
      initialValues={{
        title: '',
        message: '',
        startDate: new Date(Date.now()),
        endDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
      }}
      validateOnChange
      validationSchema={announcementSchema}
      onSubmit={async (values) => {
        await createAnnouncement({
          variables: {
            title: values.title,
            message: values.message,
            startDate: values.startDate,
            endDate: values.endDate,
          },
          refetchQueries: [
            FindMyAnnouncements, // DocumentNode object parsed with gql
            'findMyAnnouncements', // Query name
          ],
        });
        toggleCreateAnnouncementModal();
      }}
    >
      {({
        handleChange, handleSubmit, values, errors, setFieldValue, isValid,
      }) => (
        <Dialog
          visible={displayCreateAnnouncementModal}
          dismissable
          onDismiss={toggleCreateAnnouncementModal}
        >
          <Dialog.Title>Faire une annonce</Dialog.Title>
          <Dialog.Content>
            <FormControl style={styles.field}>
              <FormControl.Label>Titre</FormControl.Label>
              <Input
                style={styles.whiteBackground}
                onChangeText={handleChange('title')}
                value={values.title}
              />
            </FormControl>
            {!!errors.title && <Text style={styles.error}>{errors.title}</Text>}
            <FormControl style={styles.field}>
              <FormControl.Label>Message</FormControl.Label>
              <TextArea
                style={styles.whiteBackground}
                onChangeText={handleChange('message')}
                value={values.message}
              />
            </FormControl>
            {!!errors.message && <Text style={styles.error}>{errors.message}</Text>}
            <FormControl style={styles.field}>
              <FormControl.Label>Valable du</FormControl.Label>
              <DatePickerInput
                style={styles.whiteBackground}
                locale="fr-FR"
                label="Début"
                value={values.startDate}
                onChange={(d) => {
                  d.setHours(d.getHours() + 12);
                  if (d !== new Date(values.startDate)) setFieldValue('startDate', d);
                }}
                inputMode="start"
                mode="flat"
              />
            </FormControl>
            {!!errors.startDate && <Text style={styles.error}>{errors.startDate}</Text>}
            <FormControl style={styles.field}>
              <FormControl.Label>Jusqu'à</FormControl.Label>
              <DatePickerInput
                style={styles.whiteBackground}
                locale="fr-FR"
                label="Fin"
                value={values.endDate}
                onChange={(d) => {
                  d.setHours(d.getHours() + 12);
                  if (d !== new Date(values.startDate)) setFieldValue('endDate', d);
                }}
                inputMode="start"
                mode="flat"
              />
            </FormControl>
            {!!errors.endDate && <Text style={styles.error}>{errors.endDate}</Text>}
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={toggleCreateAnnouncementModal}>
              Annuler
            </Button>
            <Button disabled={!isValid} onPress={handleSubmit}>
              Envoyer
            </Button>
          </Dialog.Actions>
        </Dialog>
      )}
    </Formik>
  );
}

export default function AnnouncementModal() {
  return (
    <Portal>
      <NativeBaseProvider>
        <Announcement />
      </NativeBaseProvider>
    </Portal>
  );
}
