import { gql } from '@apollo/client';

export const FindManyEvents = gql`
query FindManyEvents ($status: String, $type: String) {
    findManyEvents(status: $status, type: $type) {
      id
      name
      media
      location
      startAt
      endAt
      status
      level
      type
      categories
      players {
        isConfirmed
        player {
          id
        }
      }
    }
  }
`;

export const FindOneEvent = gql`
query FindOneEvent($id: Int!) {
  findOneEvent(id: $id) {
    id
    name
    media
    location
    startAt
    endAt
    status
    level
    type
    categories
    players {
      isConfirmed
      player {
        id
        firstName
        lastName
      }
    }
  }
}
`;
