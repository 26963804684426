import React, { useContext } from 'react';
import {
  Button, Dialog, Portal, Text,
} from 'react-native-paper';
import { NativeBaseProvider } from 'native-base';
import { ModalContext } from '../../context/ModalContext';

function Confirmation() {
  const {
    displayConfirmationModal,
    toggleConfirmationModal,
  } = useContext(ModalContext);

  return (
    <Dialog
      visible={displayConfirmationModal.display}
      dismissable
      onDismiss={toggleConfirmationModal}
    >
      <Dialog.Title>
        {displayConfirmationModal.title}
      </Dialog.Title>
      <Dialog.ScrollArea>
        <Text>{displayConfirmationModal.message}</Text>
      </Dialog.ScrollArea>
      <Dialog.Actions>
        <Button onPress={toggleConfirmationModal}>Non</Button>
        <Button onPress={() => {
          displayConfirmationModal.handleSubmit();
          toggleConfirmationModal();
        }}
        >
          Oui
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}

export default function ConfirmationModal() {
  return (
    <Portal>
      <NativeBaseProvider>
        <Confirmation />
      </NativeBaseProvider>
    </Portal>
  );
}
