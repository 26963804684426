import React, { useContext, useRef, useState } from 'react';
import {
  Button, Dialog, Portal,
  ProgressBar,
  Text,
} from 'react-native-paper';
import { NativeBaseProvider } from 'native-base';
import 'setimmediate';
import { Cropper } from 'react-mobile-cropper';
import { useMutation, useQuery } from '@apollo/client';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';
import { ModalContext } from '../../context/ModalContext';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { fileSizeInBytes, getFileType, readableFileSize } from '../../utils/utils';
import 'react-mobile-cropper/dist/style.css';
import { UpdateOneFile } from '../../gql/file/mutation';
import { FindOneFile } from '../../gql/file/query';

function DocumentEditor({ email }) {
  const {
    displayDocumentEditorModal,
    toggleDocumentEditorModal,
  } = useContext(ModalContext);

  const {
    data, loading, refetch,
  } = useQuery(
    FindOneFile,
    {
      variables: {
        email,
        fileId: displayDocumentEditorModal.fileId,
      },
    },
  );

  const [updateOneFile] = useMutation(UpdateOneFile);

  const cropperRef = useRef(null);
  const previewRef = useRef(null);
  const [actionIsLoading, setActionIsLoading] = useState(false);
  const mode = 'crop';

  if (!displayDocumentEditorModal.display) return null;
  if (loading) return <ProgressBar />;

  const base64file = data?.findOneFile?.file;
  const fileType = base64file ? getFileType(base64file) : undefined;

  const onSave = async () => {
    if (cropperRef.current) {
      setActionIsLoading(true);
      const lBase64file = await manipulateAsync(
        cropperRef.current.getCanvas()?.toDataURL(),
        [],
        { format: SaveFormat.JPEG, compress: 0.9 },
      );
      await updateOneFile({
        variables: {
          file: lBase64file.uri,
          fileId: displayDocumentEditorModal.fileId,
        },
      });
      await refetch();
      toggleDocumentEditorModal();
      setActionIsLoading(false);
    }
  };

  const onUpdate = () => {
    previewRef.current?.refresh();
  };

  const cropperEnabled = mode === 'crop';

  return (
    <Dialog
      visible={displayDocumentEditorModal.display}
      dismissable
      onDismiss={toggleDocumentEditorModal}
    >
      <Dialog.Title>
        {displayDocumentEditorModal.filename}
      </Dialog.Title>
      <Dialog.Content>
        { !fileType?.startsWith('image/') ? <Text>Format de fichier non pris en charge</Text>
          : (
            <div>
              <div>
                <Cropper
                  src={base64file}
                  ref={cropperRef}
                  stencilProps={{
                    movable: cropperEnabled,
                    resizable: cropperEnabled,
                    lines: cropperEnabled,
                    handlers: cropperEnabled,
                  }}
                  backgroundWrapperProps={{
                    scaleImage: cropperEnabled,
                    moveImage: cropperEnabled,
                  }}
                  onUpdate={onUpdate}
                />
              </div>
              <Dialog.Actions>
                <Text>{readableFileSize(fileSizeInBytes(base64file))}</Text>
              </Dialog.Actions>
              <Dialog.Actions>
                <Button onPress={onSave} loading={actionIsLoading}>Enregistrer</Button>
                <Button onPress={toggleDocumentEditorModal}>Annuler</Button>
              </Dialog.Actions>
            </div>
          )}
      </Dialog.Content>
    </Dialog>
  );
}

export default function DocumentEditorModal({ email }) {
  return (
    <Portal>
      <NativeBaseProvider>
        <DocumentEditor email={email} />
      </NativeBaseProvider>
    </Portal>
  );
}
