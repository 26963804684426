import { useNavigation } from '@react-navigation/native';
import React, { useContext } from 'react';
import {
  Appbar, IconButton, List, ProgressBar,
  Searchbar,
  SegmentedButtons,
} from 'react-native-paper';
import { useMutation, useQuery } from '@apollo/client';
import { NativeBaseProvider, View } from 'native-base';
import { useToast } from 'react-native-toast-notifications';
import { styles } from '../Common.style';
import { AllUsersQuery } from '../../gql/user/query';
import { ROLE_NAMES, SCREEN_NAMES } from '../../utils/constant';
import { DeleteUserMutation } from '../../gql/user/mutation';
import { AuthContext } from '../../context/AuthContext';
import { ModalContext } from '../../context/ModalContext';
import ConfirmationModal from '../../modals/Confirmation/ConfirmationModal';

const FILTERS = {
  SUPERADMIN: 'SuperAdmins',
  COACH: 'Coachs',
  COMPETITOR: 'Compétiteurs',
  PENDING: 'En attente',
  INACTIVE: 'Inactifs',
};

const entriesFilter = Object.entries(FILTERS);

function ManageUsers() {
  const navigation = useNavigation();
  const toast = useToast();
  const { authState } = useContext(AuthContext);
  const {
    displayConfirmationModal,
    toggleConfirmationModal,
  } = useContext(ModalContext);

  const [searchQuery, setSearchQuery] = React.useState('');
  const [filters, setFilters] = React.useState(entriesFilter.map((e) => e[0]));

  const { data, loading, refetch } = useQuery(AllUsersQuery);
  const [deleteOneUser, { error: deleteError }] = useMutation(DeleteUserMutation, {
    onCompleted: (_data) => {
      toast.show(_data.deleteOneUser.message);
      refetch();
    },
  });

  const deleteUser = (user) => deleteOneUser(
    {
      variables: { email: user.email },
    },
  );

  if (deleteError) toast.show(deleteError.message);

  const isSuperAdmin = authState.role === 'SUPERADMIN';

  return (
    <View style={styles.container}>
      {loading && <ProgressBar />}
      <Appbar.Header>
        <Appbar.Content title="Liste des utilisateurs" />
        <Appbar.Action
          icon="account-plus"
          onPress={() => navigation.navigate(SCREEN_NAMES.INVITE)}
        />
      </Appbar.Header>
      {displayConfirmationModal.display && <ConfirmationModal />}
      <View>
        <Searchbar
          placeholder="Rechercher un utilisateur"
          onChangeText={(value) => setSearchQuery(value?.toLowerCase())}
          value={searchQuery}
        />
        <SegmentedButtons
          style={{ fontSize: '40px' }}
          value={filters}
          onValueChange={setFilters}
          buttons={entriesFilter.map((e) => ({ value: e[0], label: e[1] }))}
        />
        {data?.allUsers?.filter((u) => {
          // search bar
          const matchSearchBar = u.firstName?.toLowerCase().includes(searchQuery)
            || u.lastName?.toLowerCase().includes(searchQuery);
          // filters
          let matchFilter = false;
          if (filters.includes(entriesFilter[4][0])) matchFilter = u.status === 'INACTIVE';
          if (filters.includes(entriesFilter[1][0])) matchFilter = u.role === entriesFilter[1][0];
          if (filters.includes(entriesFilter[0][0])) matchFilter = u.role === entriesFilter[0][0];
          if (filters.includes(entriesFilter[2][0])) matchFilter = u.status === 'COMPETITOR';
          if (filters.includes(entriesFilter[3][0])) matchFilter = u.status === 'REVIEW_PENDING' || u.status === 'ACTIVE';
          return matchSearchBar && matchFilter;
        })?.map((d) => (
          <List.Item
            key={d.id}
            title={`${d.firstName} ${d.lastName}`}
            description={d.email}
            left={(props) => <List.Icon {...props} icon="account" />}
            right={(props) => (
              <>
                {d.status === 'INACTIVE' && <IconButton {...props} icon="progress-clock" />}
                {d.role === ROLE_NAMES.SUPERADMIN && <IconButton {...props} icon="crown" />}
                {d.role === ROLE_NAMES.COACH && <IconButton {...props} icon="account-hard-hat" />}
                {d.status === 'COMPETITOR' && <IconButton {...props} icon="karate" />}
                {d.status === 'REVIEW_PENDING' && <IconButton {...props} icon="account-reactivate" />}
                {d.status !== 'INACTIVE'
                  ? <IconButton {...props} icon="eye" onPress={() => navigation.navigate({ name: SCREEN_NAMES.PROFILE, params: { email: d.email } })} />
                  : null}
                {isSuperAdmin && d.role !== ROLE_NAMES.SUPERADMIN && (
                <IconButton
                  {...props}
                  icon="delete"
                  onPress={() => toggleConfirmationModal({
                    title: 'Confirmation',
                    message: 'Voulez-vous vraiment supprimer définitivement l\'utilisateur ? Cette action est irréversible.',
                    handleSubmit: async () => {
                      await deleteUser(d);
                    },
                  })}
                />
                )}
              </>
            )}
          />
        ))}
      </View>
    </View>
  );
}

export default function ManageUsersScreen() {
  return (
    <NativeBaseProvider>
      <ManageUsers />
    </NativeBaseProvider>
  );
}
