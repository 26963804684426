import React, { useContext } from 'react';
import { Dialog, Portal, Text } from 'react-native-paper';
import { NativeBaseProvider } from 'native-base';
import { ModalContext } from '../../context/ModalContext';

function Information() {
  const {
    displayInformationModal,
    toggleInformationModal,
  } = useContext(ModalContext);

  return (
    <Dialog
      visible={displayInformationModal.display}
      dismissable
      onDismiss={toggleInformationModal}
    >
      <Dialog.Title>
        {displayInformationModal.title}
      </Dialog.Title>
      <Dialog.ScrollArea>
        <Text>{displayInformationModal.message}</Text>
      </Dialog.ScrollArea>
    </Dialog>
  );
}

export default function InformationModal() {
  return (
    <Portal>
      <NativeBaseProvider>
        <Information />
      </NativeBaseProvider>
    </Portal>
  );
}
