import React from 'react';
import { Card, Text } from 'react-native-paper';

function MediaTab({ user }) {
  return (
    <Card.Content>
      <Text>Albums</Text>
    </Card.Content>
  );
}

export default MediaTab;
