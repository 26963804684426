import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { Text, View } from 'react-native';
import {
  Input, NativeBaseProvider, Button, Icon,
} from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { useToast } from 'react-native-toast-notifications';
import { useNavigation, useRoute } from '@react-navigation/native';
import { styles } from '../Common.style';
import { ForgotPasswordMutation, ResetPasswordMutation } from '../../gql/user/mutation';
import { emailSchema, passwordSchema } from '../../utils/yupSchema';
import { SCREEN_NAMES } from '../../utils/constant';

function ForgotAndResetPassword() {
  const [forgotPassword, { error: errorForgotPassword, data: dataForgotPassword }] = useMutation(ForgotPasswordMutation);
  const route = useRoute();
  const navigation = useNavigation();
  const [resetPassword, { error: errorResetPassword, data: dataResetPassword }] = useMutation(ResetPasswordMutation);

  const toast = useToast();

  if (errorForgotPassword) toast.show(errorForgotPassword.message);
  if (errorResetPassword) toast.show(errorResetPassword.message);

  if (dataResetPassword?.resetPassword?.status === 'success') { toast.show('Mot de passe modifié avec succès'); navigation.navigate(SCREEN_NAMES.LOGIN); }
  if (dataForgotPassword?.forgotPassword?.status === 'success') { toast.show('Email envoyé avec succès'); navigation.navigate(SCREEN_NAMES.LOGIN); }

  return route.params?.codeToken && route.params?.email ? (
    <View style={styles.container}>
      <View style={styles.Middle}>
        <Text style={styles.mainTitle}>Réinitialisation de votre mot de passe</Text>
        <Text style={styles.subTitle}>
          Veuillez saisir un nouveau mot de passe.
        </Text>

        <Formik
          initialValues={{
            email: route.params.email,
            codeToken: route.params.codeToken,
            password: '',
            confirmPassword: '',
          }}
          onSubmit={(values) => resetPassword({
            variables: {
              codeToken: values.codeToken,
              email: values.email,
              password: values.password,
            },
          })}
          validationSchema={passwordSchema.concat(emailSchema)}
        >
          {({
            handleChange, handleBlur, handleSubmit, values, errors,
          }) => (
            <>
              <View style={styles.buttonStyleX}>
                <View style={styles.input}>
                  <Input
                    InputLeftElement={(
                      <Icon
                        as={<MaterialCommunityIcons name="email" />}
                        size="sm"
                        m={2}
                        _light={{
                          color: 'black',
                        }}
                        _dark={{
                          color: 'gray.300',
                        }}
                      />
                  )}
                    variant="outline"
                    placeholder="Email"
                    _light={{
                      placeholderTextColor: 'blueGray.400',
                    }}
                    _dark={{
                      placeholderTextColor: 'blueGray.50',
                    }}
                    value={values.email}
                    onChangeText={handleChange('email')}
                    onBlur={handleBlur('email')}
                  />
                </View>
                {!!errors.email && <Text style={styles.error}>{errors.email}</Text>}
                <View style={styles.input}>
                  <Input
                    InputLeftElement={(
                      <Icon
                        as={<MaterialCommunityIcons name="form-textbox-password" />}
                        size="sm"
                        m={2}
                        _light={{
                          color: 'black',
                        }}
                        _dark={{
                          color: 'gray.300',
                        }}
                      />
                  )}
                    variant="outline"
                    placeholder="Saisir un mot de passe"
                    _light={{
                      placeholderTextColor: 'blueGray.400',
                    }}
                    _dark={{
                      placeholderTextColor: 'blueGray.50',
                    }}
                    secureTextEntry
                    value={values.password}
                    onChangeText={handleChange('password')}
                    onBlur={handleBlur('password')}
                  />
                  {!!errors.password && <Text style={styles.error}>{errors.password}</Text>}
                </View>
                <View style={styles.input}>
                  <Input
                    InputLeftElement={(
                      <Icon
                        as={<MaterialCommunityIcons name="form-textbox-password" />}
                        size="sm"
                        m={2}
                        _light={{
                          color: 'black',
                        }}
                        _dark={{
                          color: 'gray.300',
                        }}
                      />
                  )}
                    variant="outline"
                    placeholder="Confirmer votre mot de passe"
                    _light={{
                      placeholderTextColor: 'blueGray.400',
                    }}
                    _dark={{
                      placeholderTextColor: 'blueGray.50',
                    }}
                    secureTextEntry
                    value={values.confirmPassword}
                    onChangeText={handleChange('confirmPassword')}
                    onBlur={handleBlur('confirmPassword')}
                  />
                  {!!errors.confirmPassword && (
                  <Text style={styles.error}>{errors.confirmPassword}</Text>
                  )}
                </View>
              </View>

              {/* Button */}
              <View style={styles.buttonStyle}>
                <Button onPress={handleSubmit} style={styles.buttonDesign}>
                  Valider la réinitialisation du mot de passe
                </Button>
              </View>
            </>
          )}
        </Formik>
      </View>
    </View>
  )
    : (
      <View style={styles.container}>
        <View style={styles.Middle}>
          <Text style={styles.mainTitle}>Mot de passe oublié ?</Text>
          <Text style={styles.subTitle}>
            Pas de panique, un email avec des instructions sera envoyé pour réinitialiser le mot de passe.
          </Text>

          <Formik
            initialValues={{
              email: '',
            }}
            onSubmit={(values) => forgotPassword({
              variables: {
                email: values.email,
              },
            })}
            validationSchema={emailSchema}
          >
            {({
              handleChange, handleBlur, handleSubmit, values, errors,
            }) => (
              <>
                <View style={styles.buttonStyleX}>
                  <View style={styles.input}>
                    <Input
                      InputLeftElement={(
                        <Icon
                          as={<MaterialCommunityIcons name="email" />}
                          size="sm"
                          m={2}
                          _light={{
                            color: 'black',
                          }}
                          _dark={{
                            color: 'gray.300',
                          }}
                        />
                  )}
                      variant="outline"
                      placeholder="Email"
                      _light={{
                        placeholderTextColor: 'blueGray.400',
                      }}
                      _dark={{
                        placeholderTextColor: 'blueGray.50',
                      }}
                      value={values.email}
                      onChangeText={handleChange('email')}
                      onBlur={handleBlur('email')}
                    />
                  </View>
                  {!!errors.email && <Text style={styles.error}>{errors.email}</Text>}
                </View>

                {/* Button */}
                <View style={styles.buttonStyle}>
                  <Button onPress={handleSubmit} style={styles.buttonDesign}>
                    Réinitialiser le mot de passe
                  </Button>
                </View>
              </>
            )}
          </Formik>

          <StatusBar />
        </View>
      </View>
    );
}

export default function ForgotPasswordScreen() {
  return (
    <NativeBaseProvider>
      <ForgotAndResetPassword />
    </NativeBaseProvider>
  );
}
