import React, { useContext, useState } from 'react';
import {
  Appbar, IconButton, List, ProgressBar,
  SegmentedButtons,
} from 'react-native-paper';
import { useMutation, useQuery } from '@apollo/client';
import { NativeBaseProvider, ScrollView, View } from 'native-base';
import { useToast } from 'react-native-toast-notifications';
import Flag from 'react-native-flags';
import { useNavigation } from '@react-navigation/native';
import { styles } from '../Common.style';
import { FindManyEvents } from '../../gql/event/query';
import { DeleteEvent } from '../../gql/event/mutation';
import { ModalContext } from '../../context/ModalContext';
import EventModal from '../../modals/ManageEvent/CreateEventModal';
import { ISOCODE } from '../../utils/isocode';
import { EVENT_STATUS, EVENT_TYPE, SCREEN_NAMES } from '../../utils/constant';

const entriesEventStatuses = Object.entries(EVENT_STATUS);
const entriesEventTypes = Object.entries(EVENT_TYPE);

function ManageEvents() {
  // States
  const [filterEventStatus, setFilterEventStatus] = useState(undefined);
  const [filterEventType, setFilterEventType] = useState(undefined);
  const [switchCalendarView, setSwitchCalendarView] = useState(false);

  const navigation = useNavigation();
  const toast = useToast();
  const {
    displayCreateEventModal,
    toggleCreateEventModal,
  } = useContext(ModalContext);

  const { data, loading, refetch } = useQuery(FindManyEvents, {
    variables: {
      status: filterEventStatus,
      type: filterEventType,
    },
  });
  const [deleteEvent, { error: deleteError }] = useMutation(DeleteEvent, {
    onCompleted: (_data) => {
      toast.show(_data.deleteEvent.message);
      refetch();
    },
  });

  if (deleteError) toast.show(deleteError.message);

  return (
    <>
      {displayCreateEventModal && <EventModal />}
      <View style={styles.container}>
        {loading && <ProgressBar />}
        <Appbar.Header>
          <Appbar.Content title="Liste des évènements" />
          <Appbar.Action
            icon="message-plus"
            onPress={toggleCreateEventModal}
          />
        </Appbar.Header>
        <Appbar.Header style={{ flexDirection: 'column', rowGap: '2px', justifyContent: 'center' }}>
          <SegmentedButtons
            style={{ fontSize: '40px', width: '100%' }}
            value={filterEventStatus}
            onValueChange={(value) => setFilterEventStatus(value === filterEventStatus
              ? undefined : value)}
            buttons={entriesEventStatuses.map((e) => ({ value: e[0], label: e[1] }))}
          />
          <SegmentedButtons
            style={{ fontSize: '40px', width: '100%' }}
            value={filterEventType}
            onValueChange={(value) => setFilterEventType(value === filterEventType
              ? undefined : value)}
            buttons={entriesEventTypes.map((e) => ({ value: e[0], label: e[1] }))}
          />
        </Appbar.Header>
        <ScrollView>
          {data?.findManyEvents?.map((d) => {
            const splittedLocation = d.location.split(',');
            const country = splittedLocation[splittedLocation.length - 1].trim();
            return (
              <List.Item
                key={d.id}
                title={`${d.name}`}
                description={d.message}
                left={(props) => (
                  <Flag
                    {...props}
                    type="flat"
                    code={Object.entries(ISOCODE).find((e) => e[1] === country)?.[0]}
                    size={32}
                  />
                )}
                right={(props) => (
                  <>
                    <IconButton
                      {...props}
                      icon="application-edit"
                      onPress={() => navigation.navigate({ name: SCREEN_NAMES.EVENT, params: { eventId: d.id } })}
                    />
                    <IconButton
                      {...props}
                      icon="delete"
                      onPress={() => deleteEvent(
                        {
                          variables: { deleteEventId: Number(d.id) },
                        },
                      )}
                    />
                  </>
                )}
              />
            );
          })}
        </ScrollView>
      </View>
    </>
  );
}

export default function ManageEventsScreen() {
  return (
    <NativeBaseProvider>
      <ManageEvents />
    </NativeBaseProvider>
  );
}
