import { gql } from '@apollo/client';

export const CreateEvent = gql`
mutation CreateEvent($name: String!, $location: String!, $startAt: DateTime!, $endAt: DateTime!, $categories: [String]!, $media: String, $level: String, $type: String) {
  createEvent(name: $name, location: $location, startAt: $startAt, endAt: $endAt, categories: $categories, media: $media, level: $level, type: $type) {
    id
  }
}`;

export const UpdateEvent = gql`
mutation UpdateEvent($id: Int!, $name: String, $location: String, $startAt: DateTime, $endAt: DateTime, $media: String, $level: String, $type: String, $status: String, $categories: [String]) {
  updateEvent(id: $id, name: $name, location: $location, startAt: $startAt, endAt: $endAt, media: $media, level: $level, type: $type, status: $status, categories: $categories) {
    id
  }
}
`;

export const DeleteEvent = gql`
mutation CreateEvent($deleteEventId: Int!) {
    deleteEvent(id: $deleteEventId) {
      message
      status
    }
  }
`;

export const JoinEvent = gql`
mutation JoinEvent($joinEventId: Int!) {
  joinEvent(id: $joinEventId) {
    status
    message
  }
}
`;

export const ConfirmEvent = gql`
mutation ConfirmPlayerOnEvent($playerId: Int!, $eventId: Int!, $isConfirmed: Boolean!) {
  confirmPlayerOnEvent(playerId: $playerId, eventId: $eventId, isConfirmed: $isConfirmed) {
    status
    message
  }
}
`;

export const LeaveEvent = gql`
mutation LeaveEvent($eventId: Int!, $userId: Int) {
  leaveEvent(eventId: $eventId, userId: $userId) {
    status
    message
  }
}
`;
