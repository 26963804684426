import React, { useContext, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  Button, Dialog, Portal,
  ProgressBar,
  Text,
} from 'react-native-paper';
import { NativeBaseProvider, ScrollView } from 'native-base';
import Slider from '@react-native-community/slider';
import 'setimmediate';
import { useQuery } from '@apollo/client';
import { ModalContext } from '../../context/ModalContext';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { fileSizeInBytes, getFileType, readableFileSize } from '../../utils/utils';
import { FindOneFile } from '../../gql/file/query';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

function DocumentViewer({ email }) {
  const {
    displayDocumentViewerModal,
    toggleDocumentViewerModal,
  } = useContext(ModalContext);

  const { data, loading, error } = useQuery(
    FindOneFile,
    {
      variables: {
        email,
        fileId: displayDocumentViewerModal.fileId,
      },
    },
  );

  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);

  if (!displayDocumentViewerModal.display) return null;
  if (loading) return <ProgressBar />;

  const base64file = data?.findOneFile?.file;
  const fileType = base64file ? getFileType(base64file) : undefined;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function incrementPage() {
    setPageNumber(pageNumber + 1);
  }

  function decrementPage() {
    setPageNumber(pageNumber - 1);
  }

  return (
    <Dialog
      visible={displayDocumentViewerModal.display}
      dismissable
      onDismiss={toggleDocumentViewerModal}
    >
      <Dialog.Title>
        {displayDocumentViewerModal.filename}
      </Dialog.Title>
      <Dialog.ScrollArea>
        <Slider
          minimumValue={0.5}
          value={scale}
          maximumValue={4}
          onValueChange={(e) => {
            setScale(e);
          }}
        />

        <ScrollView height="60vh" horizontal>
          <ScrollView>
            { fileType === 'application/pdf'
              ? (
                <Document
                  file={base64file}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page
                    scale={scale}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    pageNumber={pageNumber}
                  />
                </Document>
              ) : (
                <img
                  alt={displayDocumentViewerModal.filename}
                  style={{
                    backgroundColor: 'transparent',
                    zoom: scale,
                    maxHeight: '80vh',
                    maxWidth: '80vw',
                  }}
                  src={base64file}
                />
              )}

          </ScrollView>
        </ScrollView>
      </Dialog.ScrollArea>
      { fileType === 'application/pdf'
        ? (
          <Dialog.Actions>
            {pageNumber > 1 && (
              <Button onPress={decrementPage}>
                Page précédente
              </Button>
            )}
            {pageNumber < numPages && (
              <Button onPress={incrementPage}>
                Page suivante
              </Button>
            )}
            <Text>{readableFileSize(fileSizeInBytes(base64file))}</Text>
          </Dialog.Actions>
        ) : null}
      <Dialog.Actions>
        <Button onPress={toggleDocumentViewerModal}>
          Fermer
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}

export default function DocumentViewerModal({ email }) {
  return (
    <Portal>
      <NativeBaseProvider>
        <DocumentViewer email={email} />
      </NativeBaseProvider>
    </Portal>
  );
}
