import { gql } from '@apollo/client';

export const FindMyWeightsOnProfile = gql`
query FindMyWeightsOnProfile {
    findMyProfile {
      id
      weights {
        value
        createdAt
      }
    }
  }
`;

export const FindWeightsOnOneProfile = gql`
query FindWeightsOnOneProfile($profileId: Int!) {
  findOneProfile(profileId: $profileId) {
    id
    weights {
      value
      createdAt
    }
  }

}
`;
