import debounce from 'lodash.debounce';
import { View } from 'native-base';
import React, { useCallback, useState } from 'react';
import { Text, TouchableOpacity } from 'react-native';
import AutocompleteInput from 'react-native-autocomplete-input';

function AutocompleteLocation({
  value,
  handleChangeText,
  handleSubmit,
  disabled,
}) {
  const [locations, setLocations] = useState([]);
  const [hideResults, setHideResults] = useState(false);

  const callLocationIQ = ({ query }) => {
    const options = { method: 'GET', headers: { accept: 'application/json', 'Access-Control-Request-Headers': '*' } };

    fetch(`https://api.locationiq.com/v1/autocomplete?key=pk.b130589e2abda1c332d865f307b1316a&q=${query}&limit=2&dedupe=1`, options)
      .then((response) => response.json())
      .then((response) => {
        setLocations(response.map((r) => r.display_name));
      })
      .catch((err) => console.error(err));
  };

  const debouncedFilter = useCallback(debounce((query) => callLocationIQ(query), 500), []);

  return (
    <AutocompleteInput
      data={locations}
      hideResults={hideResults}
      value={value}
      disabled={disabled}
      onChangeText={(query) => {
        if (!disabled) {
          if (query.length > 5) debouncedFilter({ query });
          handleChangeText(query);
          setHideResults(false);
        }
      }}
      renderResultList={({ data, style }) => (
        <View style={{ ...style[0], position: 'relative' }}>
          {data?.map((item, index) => (
            <TouchableOpacity
              onPress={(innerValue) => {
                handleSubmit(innerValue.target.textContent);
                setHideResults(true);
              }}
            >
              <Text key={index}>{item}</Text>
            </TouchableOpacity>
          ))}
        </View>
      )}
    />
  );
}

export default AutocompleteLocation;
