import React, { useContext, useState } from 'react';
import { useRoute } from '@react-navigation/native';
import { View } from 'react-native';
import {
  Card, Chip, IconButton, Menu, ProgressBar, Text,
} from 'react-native-paper';
import { useMutation, useQuery } from '@apollo/client';
import { NativeBaseProvider } from 'native-base';
import { launchImageLibrary } from 'react-native-image-picker';
import { AuthContext } from '../../context/AuthContext';
import { FindOneEvent } from '../../gql/event/query';
import { styles } from '../Common.style';
import {
  EVENT_LEVEL, EVENT_STATUS, EVENT_TYPE, ROLE_NAMES,
} from '../../utils/constant';
import EventTabs from './EventTabs';
import { UpdateEvent } from '../../gql/event/mutation';

function Event() {
  const route = useRoute();
  const { authState } = useContext(AuthContext);

  const [visibleEventType, setVisibleEventType] = useState(false);
  const [visibleEventLevel, setVisibleEventLevel] = useState(false);
  const [visibleEventStatus, setVisibleEventStatus] = useState(false);

  const isSuperAdmin = authState.role === ROLE_NAMES.SUPERADMIN;

  const {
    data: oneEventQuery,
    loading: oneEventQueryLoading,
    error: oneEventQueryError,
  } = useQuery(FindOneEvent, {
    variables: {
      id: Number(route?.params?.eventId),
    },
  });
  const event = oneEventQuery?.findOneEvent;

  const [updateEvent] = useMutation(UpdateEvent);

  const saveEventCallback = async (data) => {
    if (data) {
      await updateEvent({
        variables: {
          id: Number(event.id),
          type: data.type
            ? Object.entries(EVENT_TYPE).find((e) => e[1] === data.type)[0]
            : undefined,
          level: data.level
            ? Object.entries(EVENT_LEVEL).find((e) => e[1] === data.level)[0]
            : undefined,
          status: data.status
            ? Object.entries(EVENT_STATUS).find((e) => e[1] === data.status)[0]
            : undefined,
          media: data.media,
        },
        refetchQueries: [
          FindOneEvent,
          'FindOneEvent',
        ],
      });
    }
  };

  return (
    <View style={styles.container}>
      {oneEventQueryLoading && <ProgressBar />}
      {oneEventQueryError && (
      <View style={styles.Middle}>
        <Text>{oneEventQueryError.message}</Text>
      </View>
      )}

      {event ? (
        <>
          <Card>
            <Card.Cover
              style={{ alignItems: 'center', backgroundColor: 'transparent' }}
              source={{ uri: event.media ?? '', width: '100%' }}
            />
            {isSuperAdmin ? (
              <Card.Actions>
                <Menu
                  visible={visibleEventType}
                  anchorPosition="bottom"
                  onDismiss={() => setVisibleEventType(!visibleEventType)}
                  anchor={(
                    <Chip closeIcon="application-edit" onClose={() => setVisibleEventType(!visibleEventType)}>
                      {EVENT_TYPE[event.type]}
                    </Chip>
                )}
                >
                  {Object.entries(EVENT_TYPE).filter((e) => e[0] !== event.type)
                    .map((e) => (
                      <Menu.Item
                        onPress={(d) => saveEventCallback({ type: d.target.textContent })}
                        title={e[1]}
                      />
                    ))}
                </Menu>
                <Menu
                  visible={visibleEventLevel}
                  anchorPosition="bottom"
                  onDismiss={() => setVisibleEventLevel(!visibleEventLevel)}
                  anchor={(
                    <Chip closeIcon="application-edit" onClose={() => setVisibleEventLevel(!visibleEventLevel)}>
                      {EVENT_LEVEL[event.level]}
                    </Chip>
                )}
                >
                  {Object.entries(EVENT_LEVEL).filter((e) => e[0] !== event.level)
                    .map((e) => (
                      <Menu.Item
                        onPress={(d) => saveEventCallback({ level: d.target.textContent })}
                        title={e[1]}
                      />
                    ))}
                </Menu>
                <Menu
                  visible={visibleEventStatus}
                  anchorPosition="bottom"
                  onDismiss={() => setVisibleEventStatus(!visibleEventStatus)}
                  anchor={(
                    <Chip closeIcon="application-edit" onClose={() => setVisibleEventStatus(!visibleEventStatus)}>
                      {EVENT_STATUS[event.status]}
                    </Chip>
                )}
                >
                  {Object.entries(EVENT_STATUS).filter((e) => e[0] !== event.status)
                    .map((e) => (
                      <Menu.Item
                        onPress={(d) => saveEventCallback({ status: d.target.textContent })}
                        title={e[1]}
                      />
                    ))}
                </Menu>
                <IconButton
                  icon="image-edit"
                  size={20}
                  onPress={() => launchImageLibrary(
                    {
                      mediaType: 'photo',
                      maxWidth: '460px',
                      maxHeight: '200px',
                      includeBase64: true,
                    },
                    ({ didCancel, errorCode, assets }) => {
                      if (!didCancel
                      && !errorCode
                      && assets.length > 0) saveEventCallback({ media: assets[0].uri });
                    },
                  )}
                />
              </Card.Actions>
            ) : (
              <Card.Actions>
                <Chip>
                  {EVENT_TYPE[event.type]}
                </Chip>
                <Chip>
                  {EVENT_LEVEL[event.level]}
                </Chip>
                <Chip>
                  {EVENT_STATUS[event.status]}
                </Chip>
              </Card.Actions>
            )}
          </Card>
          <EventTabs event={event} disabled={!isSuperAdmin} />
        </>
      ) : null}
    </View>
  );
}

export default function EventScreen() {
  return (
    <NativeBaseProvider>
      <Event />
    </NativeBaseProvider>
  );
}
