import React, { createContext, useState } from 'react';

const ModalContext = createContext();
const { Provider } = ModalContext;

function ModalProvider({ children }) {
  const [displayWeightModal, setDiplayWeightModal] = useState({
    display: false,
    profileId: undefined,
  });
  const [displayCreateAnnouncementModal, setDisplayCreateAnnouncementModal] = useState(false);
  const [displayUsersOnAnnouncementModal, setDisplayUsersOnAnnouncementModal] = useState(false);
  const [displayCreateEventModal, setDisplayCreateEventModal] = useState(false);
  const [displayDocumentViewerModal, setDisplayDocumentViewerModal] = useState({
    display: false,
    fileId: undefined,
    filename: undefined,
    isWeb: false,
  });
  const [displayDocumentEditorModal, setDisplayDocumentEditorModal] = useState({
    display: false,
    fileId: undefined,
    filename: undefined,
    isWeb: false,
  });
  const [displayInformationModal, setDisplayInformationModal] = useState({
    display: false,
    title: undefined,
    message: undefined,
  });
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState({
    display: false,
    title: undefined,
    message: undefined,
    handleSubmit: () => undefined,
  });
  const [displayPlayersOnEventModal, setDisplayPlayersOnEventModal] = useState({
    display: false,
    action: undefined,
  });

  const toggleWeightModal = (props) => {
    setDiplayWeightModal({
      display: !displayWeightModal.display,
      profileId: props?.profileId,
    });
  };

  const toggleCreateAnnouncementModal = () => {
    setDisplayCreateAnnouncementModal(!displayCreateAnnouncementModal);
  };

  const toggleCreateEventModal = () => {
    setDisplayCreateEventModal(!displayCreateEventModal);
  };

  const toggleUsersOnAnnouncementModal = () => {
    setDisplayUsersOnAnnouncementModal(!displayUsersOnAnnouncementModal);
  };

  const toggleDocumentViewerModal = (props) => {
    setDisplayDocumentViewerModal({
      display: !displayDocumentViewerModal.display,
      fileId: props?.fileId,
      filename: props?.filename,
      isWeb: props?.isWeb,
    });
  };

  const toggleDocumentEditorModal = (props) => {
    setDisplayDocumentEditorModal({
      display: !displayDocumentEditorModal.display,
      fileId: props?.fileId,
      filename: props?.filename,
      isWeb: props?.isWeb,
    });
  };

  const toggleInformationModal = (props) => {
    setDisplayInformationModal({
      display: !displayInformationModal.display,
      title: props?.title,
      message: props?.message,
    });
  };

  const toggleConfirmationModal = (props) => {
    setDisplayConfirmationModal({
      display: !displayConfirmationModal.display,
      title: props?.title,
      message: props?.message,
      handleSubmit: props?.handleSubmit,
    });
  };

  const togglePlayersOnEventModal = (props) => {
    setDisplayPlayersOnEventModal({
      display: !displayPlayersOnEventModal.display,
      action: props?.action,
    });
  };

  return (
    <Provider
      value={{
        displayWeightModal,
        toggleWeightModal,
        displayCreateAnnouncementModal,
        toggleCreateAnnouncementModal,
        displayUsersOnAnnouncementModal,
        toggleUsersOnAnnouncementModal,
        displayCreateEventModal,
        toggleCreateEventModal,
        displayDocumentViewerModal,
        toggleDocumentViewerModal,
        displayInformationModal,
        toggleInformationModal,
        displayConfirmationModal,
        toggleConfirmationModal,
        displayPlayersOnEventModal,
        togglePlayersOnEventModal,
        displayDocumentEditorModal,
        toggleDocumentEditorModal,
      }}
    >
      {children}
    </Provider>
  );
}

export { ModalContext, ModalProvider };
