import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Platform, Pressable, ScrollView, View,
} from 'react-native';
import * as base64ToBlob from 'b64-to-blob';
import {
  Button, Card, Chip, Dialog, List, Portal,
  Text,
} from 'react-native-paper';
import { getDocumentAsync } from 'expo-document-picker';
import { Picker } from '@react-native-picker/picker';
import { DeleteFileMutation, UploadFileMutation } from '../../../gql/user/mutation';
import { FindManyFilesQuery, FindOneFile } from '../../../gql/file/query';
import DocumentViewerModal from '../../../modals/DocumentViewer/DocumentViewerModal';
import ConfirmationModal from '../../../modals/Confirmation/ConfirmationModal';
import { ModalContext } from '../../../context/ModalContext';
import { FindOneUserQuery } from '../../../gql/user/query';
import { computeAge, getFileType } from '../../../utils/utils';
import { AuthContext } from '../../../context/AuthContext';
import { ROLE_NAMES } from '../../../utils/constant';
import DocumentEditorModal from '../../../modals/DocumentEditor/DocumentEditorModal';

export const DOCUMENT_TYPE = {
  CNI_RECTO: {
    name: 'Carte identité (recto)', color: 'lightyellow', visible: 'always', order: 1,
  },
  CNI_VERSO: {
    name: 'Carte identité (verso)', color: 'lightyellow', visible: 'always', order: 2,
  },
  CNI_RL_RECTO: {
    name: 'Carte identité responsable légal (recto)', color: 'lightyellow', visible: 'isMinor', order: 3,
  },
  CNI_RL_VERSO: {
    name: 'Carte identité responsable légal (verso)', color: 'lightyellow', visible: 'isMinor', order: 4,
  },
  EXTRAIT_LIVRET_FAMILLE: {
    name: 'Extrait livret de famille', color: 'lightyellow', visible: 'isMinor', order: 5,
  },
  EXTRAIT_LIVRET_FAMILLE_RL: {
    name: 'Extrait livret de famille du responsable légal', color: 'lightyellow', visible: 'isMinor', order: 6,
  },
  PASSPORT: {
    name: 'Passport', color: 'lightyellow', visible: 'always', order: 7,
  },
  CARTE_VITALE: {
    name: 'Carte vitale', color: 'lightblue', visible: 'always', order: 8,
  },
  MUTUELLE: {
    name: 'Mutuelle', color: 'lightblue', visible: 'always', order: 9,
  },
  CARTE_VITALE_INTERNATIONALE: {
    name: 'Carte vitale internationale', color: 'lightblue', visible: 'always', order: 10,
  },
  AUTORISATION_SORTIE_TERRITOIRE: {
    name: 'Autorisation sortie territoire', color: 'lightyellow', visible: 'isMinor', order: 11,
  },
  FICHE_SANITAIRE_LIAISON: {
    name: 'Fiche sanitaire de liaison', color: 'lightblue', visible: 'isMinor', order: 12,
  },
  VACCINS: {
    name: 'Vaccins', color: 'lightblue', visible: 'always', order: 13,
  },
  OBJECTIF_PALMARES: {
    name: 'Objectifs et palmarès compétiteurs', color: 'orange', visible: 'always', order: 14,
  },
  AUTORISATION_PARENTALE: {
    name: 'Autorisation parentale', color: 'orange', visible: 'isMinor', order: 15,
  },
  LICENCE: {
    name: 'Licence sportive', color: 'lightgreen', visible: 'always', order: 16,
  },
  CERTIFICAT_MEDICAL: {
    name: 'Certificat medical', color: 'lightblue', visible: 'none', order: 17,
  },
  AUTRE: {
    name: 'Fiche d\'inscription', color: 'orange', visible: 'always', order: 18,
  },
};

function DocumentTab({ user }) {
  const { authState } = useContext(AuthContext);
  const {
    displayDocumentViewerModal,
    toggleDocumentViewerModal,
    displayConfirmationModal,
    toggleConfirmationModal,
    displayDocumentEditorModal,
    toggleDocumentEditorModal,
  } = useContext(ModalContext);
  const { data, refetch } = useQuery(FindManyFilesQuery, {
    variables: {
      email: user.email,
    },
  });
  const files = data?.findManyFiles;

  const [addFileForUser] = useMutation(UploadFileMutation);
  const [deleteFileForUser] = useMutation(DeleteFileMutation);
  const [loadFile] = useLazyQuery(
    FindOneFile,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [displayFileTypeModal, setDisplayFileTypeModal] = useState(false);

  const visible = ['always'];
  if (computeAge(user.profile?.dateOfBirth) < 18) visible.push('isMinor');

  const fileTypes = Object.entries(DOCUMENT_TYPE)
    .filter((k) => visible.includes(k[1].visible) && !files?.map((f) => f.type).includes(k[0]));
  const [documentType, setDocumentType] = useState(fileTypes.length > 0 ? fileTypes[0][0] : undefined);

  const viewFile = async (fileId, filename) => {
    toggleDocumentViewerModal({
      fileId, filename, isWeb: Platform.OS === 'web',
    });
  };

  const editFile = async (fileId, filename) => {
    toggleDocumentEditorModal({
      fileId, filename, isWeb: Platform.OS === 'web',
    });
  };

  useEffect(() => {
    if (fileTypes.length > 0) setDocumentType(fileTypes[0][0]);
  }, [files]);

  const downloadFile = async (fileId, filename) => {
    const fileData = await loadFile({
      variables: {
        email: user.email,
        fileId,
      },
    });
    const base64file = fileData.data.findOneFile.file;
    if (Platform.OS === 'web') {
      const type = getFileType(base64file);
      const cleanedBase64 = base64file.split(',')[1];
      // generate a blob, then a file and then save the file.
      const blob = base64ToBlob(cleanedBase64, type);
      const file = new File([blob], filename, { type });

      import('file-saver').then((fs) => {
        fs.default.saveAs(file);
      });
    } else {
      const FileSystem = React.lazy(() => import('expo-file-system'));
      FileSystem.writeAsStringAsync(filename, base64file, { encoding: 'base64' });
    }
  };

  const uploadFile = async () => {
    const result = await getDocumentAsync({
      type: ['application/pdf', 'image/jpeg', 'application/msword'],
    });
    if (result.type === 'success') {
      await addFileForUser({
        variables: {
          profileId: user.profile.id,
          file: result.uri,
          name: result.name,
          type: documentType,
        },
        refetchQueries: [
          FindOneUserQuery, // DocumentNode object parsed with gql
          'findOneUser', // Query name
        ],
      });
      refetch();

      setDisplayFileTypeModal(false);
    }
  };

  const deleteFile = async (fileId) => {
    if (fileId) {
      await deleteFileForUser({
        variables: {
          profileId: user.profile.id,
          fileId,
        },
      });
      refetch();
    }
  };

  const leftComponent = (f) => (
    <Pressable onPress={() => viewFile(f.id, f.name)}>
      <List.Icon icon="eye" />
    </Pressable>
  );

  const rightComponent = (f) => (
    <View style={{ flexDirection: 'row', gap: 10 }}>
      <Chip
        style={{ backgroundColor: DOCUMENT_TYPE[f.type].color }}
      >
        {DOCUMENT_TYPE[f.type].name}
      </Chip>
      <Pressable onPress={() => editFile(f.id, f.name)}>
        <List.Icon icon="image-edit" />
      </Pressable>
      <Pressable onPress={() => downloadFile(f.id, f.name)}>
        <List.Icon icon="download" />
      </Pressable>
      {(user.status !== 'COMPETITOR' || authState.role === ROLE_NAMES.SUPERADMIN) && (
      <Pressable onPress={() => toggleConfirmationModal({
        title: 'Confirmation',
        message: 'Voulez-vous vraiment supprimer ce document ?',
        handleSubmit: () => deleteFile(f.id),
      })}
      >
        <List.Icon icon="trash-can" />
      </Pressable>
      )}
    </View>
  );

  return displayDocumentViewerModal.display || displayDocumentEditorModal.display
    ? (
      <>
        { displayDocumentViewerModal.display && <DocumentViewerModal email={user?.email} />}
        { displayDocumentEditorModal.display && <DocumentEditorModal email={user?.email} />}
      </>
    )
    : (
      <>
        {displayConfirmationModal.display && <ConfirmationModal />}
        <Portal>
          <Dialog visible={displayFileTypeModal} onDismiss={() => setDisplayFileTypeModal(false)}>
            <Dialog.Title>Type de document</Dialog.Title>
            <Dialog.Content>
              <Picker
                style={{ minHeight: 40 }}
                selectedValue={documentType}
                onValueChange={(itemValue) => setDocumentType(itemValue)}
              >
                {fileTypes.map((k) => <Picker.Item label={k[1].name} value={k[0]} />)}
              </Picker>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={() => uploadFile()}>Parcourir</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
        <ScrollView>
          <Card.Content>
            <Card.Actions>
              <Text>
                {files?.length}
                /
                {Object.entries(DOCUMENT_TYPE).filter((k) => visible.includes(k[1].visible)).length}
              </Text>
              <Button
                disabled={fileTypes.length === 0}
                onPress={() => setDisplayFileTypeModal(true)}
              >
                Ajouter un document
              </Button>
            </Card.Actions>
            {files?.slice()
              .sort((a, b) => (DOCUMENT_TYPE[a.type].order > DOCUMENT_TYPE[b.type].order ? 1 : -1))
              .map((f) => (
                <List.Item
                  key={f.id}
                  onPress={() => viewFile(f.id, f.name)}
                  title={f.name}
                  left={() => leftComponent(f)}
                  right={() => rightComponent(f)}
                />
              ))}
          </Card.Content>
        </ScrollView>
      </>
    );
}

export default DocumentTab;
