import { gql } from '@apollo/client';

export const CreateAnnouncement = gql`
mutation CreateAnnoucement($title: String!, $message: String!, $startDate: DateTime!, $endDate: DateTime!) {
  createAnnouncement(title: $title, message: $message, startDate: $startDate, endDate: $endDate) {
    id
  }
}
`;

export const DeleteAnnouncement = gql`
mutation DeleteAnnouncement($deleteAnnouncementId: Int!) {
  deleteAnnouncement(id: $deleteAnnouncementId) {
    message
    status
  }
}
`;

export const AcknowledgeAnnouncement = gql`
mutation AcknowledgeAnnoucement($acknowledgeAnnouncementId: Int!, $hasBeenRead: Boolean!) {
  acknowledgeAnnouncement(id: $acknowledgeAnnouncementId, hasBeenRead: $hasBeenRead) {
    announcementId
  }
}
`;
