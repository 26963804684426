import * as React from 'react';
import { BottomNavigation } from 'react-native-paper';
import InfoTab from './InfoTab';
import DocumentTab from './DocumentTab';
import MediaTab from './MediaTab';

function ProfileTabs({ user, disabled = false }) {
  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    {
      key: 'info',
      title: 'Infos',
      focusedIcon: 'account-details',
      unfocusedIcon: 'account-details-outline',
    },
    {
      key: 'documents',
      title: 'Documents',
      focusedIcon: 'file-multiple',
      unfocusedIcon: 'file-multiple-outline',
    },
    {
      key: 'medias',
      title: 'Medias',
      focusedIcon: 'file-video',
      unfocusedIcon: 'file-video-outline',
    },
  ]);

  const renderScene = BottomNavigation.SceneMap({
    info: () => <InfoTab user={user} disabled={disabled} />,
    documents: () => <DocumentTab user={user} disabled={disabled} />,
    medias: () => <MediaTab user={user} disabled={disabled} />,
  });

  return (
    <BottomNavigation
      navigationState={{ index, routes }}
      onIndexChange={setIndex}
      renderScene={renderScene}
    />
  );
}

export default ProfileTabs;
