import React, { useContext } from 'react';
import { Card, IconButton, Text } from 'react-native-paper';
import { FormControl, Input } from 'native-base';
import { ScrollView } from 'react-native-gesture-handler';
import { useMutation } from '@apollo/client';
import { DatePickerInput } from 'react-native-paper-dates';
import { Formik } from 'formik';
import { Picker } from '@react-native-picker/picker';
import { styles } from '../../Common.style';
import { UpdateProfileMutation } from '../../../gql/profile/mutation';
import { FindOneUserQuery } from '../../../gql/user/query';
import { phoneSchema } from '../../../utils/yupSchema';
import {
  GENDER_TYPE, GRADE, LEGAL_GUARDIAN_TYPE, ROLE_NAMES,
} from '../../../utils/constant';
import { ISOCODE } from '../../../utils/isocode';
import AutocompleteLocation from '../../../components/commons/AutocompleteLocation';
import { computeAge } from '../../../utils/utils';
import { AuthContext } from '../../../context/AuthContext';
import { UpdateOneUser } from '../../../gql/user/mutation';

function InfoTab({ user, disabled = false }) {
  const { authState } = useContext(AuthContext);
  const [
    updateProfileForUser,
  ] = useMutation(UpdateProfileMutation);
  const [updateOneUser] = useMutation(UpdateOneUser);
  const saveProfileCallback = async (data) => {
    if (data) {
      // upload asset using gql
      if (authState.role === ROLE_NAMES.SUPERADMIN) {
        await updateOneUser({
          variables: {
            updateOneUserId: user.id,
            email: data.email,
            lastName: data.lastName,
            firstName: data.firstName,
            profile: {
              address: data.address,
              phone: data.phone,
              dateOfBirth: data.dateOfBirth,
              license: data.license,
              grade: data.grade,
              locationOfBirth: data.locationOfBirth,
              bio: data.bio,
              gender: data.gender,
              nationality: data.nationality,
              rlType: data.rlType,
              rlFirstName: data.rlFirstName,
              rlLastName: data.rlLastName,
              rlEmail: data.rlEmail,
              rlAddress: data.rlAddress,
              rlPhone: data.rlPhone,
            },
          },
          refetchQueries: [
            FindOneUserQuery, // DocumentNode object parsed with gql
            'findOneUser', // Query name
          ],
        });
      } else {
        await updateProfileForUser({
          variables: {
            address: data.address,
            phone: data.phone,
            dateOfBirth: data.dateOfBirth,
            license: data.license,
            grade: data.grade,
            locationOfBirth: data.locationOfBirth,
            bio: data.bio,
            gender: data.gender,
            nationality: data.nationality,
            rlType: data.rlType,
            rlFirstName: data.rlFirstName,
            rlLastName: data.rlLastName,
            rlEmail: data.rlEmail,
            rlAddress: data.rlAddress,
            rlPhone: data.rlPhone,
          },
          refetchQueries: [
            FindOneUserQuery, // DocumentNode object parsed with gql
            'findOneUser', // Query name
          ],
        });
      }
    }
  };

  return (
    <ScrollView keyboardShouldPersistTaps="always">
      <Card.Content>
        <Formik
          initialValues={{
            lastName: user.lastName,
            firstName: user.firstName,
            email: user.email,
            address: user.profile?.address,
            phone: user.profile?.phone,
            dateOfBirth: user.profile?.dateOfBirth,
            license: user.profile?.license,
            grade: user.profile?.grade,
            locationOfBirth: user.profile?.locationOfBirth,
            gender: user.profile?.gender,
            nationality: user.profile?.nationality,
            rlType: user.profile?.rlType,
            rlFirstName: user.profile?.rlFirstName,
            rlLastName: user.profile?.rlLastName,
            rlEmail: user.profile?.rlEmail,
            rlAddress: user.profile?.rlAddress,
            rlPhone: user.profile?.rlPhone,
          }}
          validateOnChange
          validationSchema={phoneSchema}
        >
          {({
            handleChange, handleBlur, values, errors, setFieldValue,
          }) => (
            <>
              <FormControl style={styles.field}>
                <FormControl.Label>Nom</FormControl.Label>
                <Input
                  isDisabled={disabled}
                  onChangeText={handleChange('lastName')}
                  onBlur={() => saveProfileCallback({ lastName: values.lastName })}
                  InputLeftElement={<IconButton icon="account" />}
                  value={values.lastName}
                />
              </FormControl>

              <FormControl style={styles.field}>
                <FormControl.Label>Prénom</FormControl.Label>
                <Input
                  isDisabled={disabled}
                  onChangeText={handleChange('firstName')}
                  onBlur={() => saveProfileCallback({ firstName: values.firstName })}
                  InputLeftElement={<IconButton icon="account" />}
                  value={values.firstName}
                />
              </FormControl>

              <FormControl style={styles.field}>
                <FormControl.Label>Email</FormControl.Label>
                <Input
                  isDisabled={disabled}
                  onChangeText={handleChange('email')}
                  onBlur={() => saveProfileCallback({ email: values.email })}
                  InputLeftElement={<IconButton icon="email" />}
                  value={values.email}
                />
              </FormControl>

              <FormControl style={styles.field}>
                <FormControl.Label>Genre</FormControl.Label>
                <Picker
                  style={{ minHeight: 40 }}
                  selectedValue={values.gender}
                  onBlur={handleChange('gender')}
                  onValueChange={(value) => { handleBlur('gender'); saveProfileCallback({ gender: value }); }}
                >
                  {Object.entries(GENDER_TYPE)
                    .map((k) => <Picker.Item label={k[1]} value={k[0]} />)}
                </Picker>
              </FormControl>

              <FormControl style={styles.field}>
                <FormControl.Label>Nationalité</FormControl.Label>
                <Picker
                  style={{ minHeight: 40 }}
                  selectedValue={values.nationality}
                  onBlur={handleChange('nationality')}
                  onValueChange={(value) => { handleBlur('nationality'); saveProfileCallback({ nationality: value }); }}
                >
                  {Object.entries(ISOCODE)
                    .sort((a, b) => a[1].localeCompare(b[1], 'en'))
                    .map((k) => <Picker.Item label={k[1]} value={k[1]} />)}
                </Picker>
              </FormControl>

              <FormControl style={styles.field}>
                <FormControl.Label>Adresse</FormControl.Label>
                <AutocompleteLocation
                  value={values.address}
                  handleChangeText={setFieldValue}
                  handleSubmit={(value) => {
                    setFieldValue('address', value);
                    saveProfileCallback(
                      { address: value },
                    );
                  }}
                />
              </FormControl>

              <FormControl style={styles.field}>
                <FormControl.Label>Téléphone</FormControl.Label>
                <Input
                  InputLeftElement={<IconButton icon="phone" />}
                  onChangeText={handleChange('phone')}
                  onBlur={({ target }) => {
                    handleBlur('phone');
                    if (!errors.phone && target.value !== '') saveProfileCallback({ phone: target.value });
                  }}
                  value={values.phone}
                />
                {!!errors.phone && <Text style={styles.error}>{errors.phone}</Text>}
              </FormControl>

              <FormControl style={styles.field}>
                <FormControl.Label>Date de naissance</FormControl.Label>
                <DatePickerInput
                  style={styles.whiteBackground}
                  locale="fr-FR"
                  label="Date de naissance"
                  value={new Date(values.dateOfBirth ?? Date.now())}
                  onChange={(d) => {
                    d.setHours(d.getHours() + 12);
                    if (d !== new Date(values.dateOfBirth)) saveProfileCallback({ dateOfBirth: d });
                  }}
                  inputMode="start"
                  mode="flat"
                />
              </FormControl>

              <FormControl style={styles.field}>
                <FormControl.Label>Lieu de naissance</FormControl.Label>
                <Input
                  onChangeText={handleChange('locationOfBirth')}
                  onBlur={() => saveProfileCallback({ locationOfBirth: values.locationOfBirth })}
                  InputLeftElement={<IconButton icon="map-marker" />}
                  value={values.locationOfBirth}
                />
              </FormControl>

              <FormControl style={styles.field}>
                <FormControl.Label>Grade</FormControl.Label>
                <Picker
                  style={{ minHeight: 40 }}
                  selectedValue={values.grade}
                  onBlur={handleChange('grade')}
                  disabled={disabled}
                  onValueChange={(value) => { handleBlur('grade'); saveProfileCallback({ grade: value }); }}
                >
                  {Object.entries(GRADE)
                    .map((k) => <Picker.Item label={k[1]} value={k[0]} />)}
                </Picker>
              </FormControl>

              <FormControl style={styles.field}>
                <FormControl.Label>Numéro licence sportive</FormControl.Label>
                <Input
                  onChangeText={handleChange('license')}
                  onBlur={() => saveProfileCallback({ license: values.license })}
                  InputLeftElement={<IconButton icon="identifier" />}
                  value={values.license}
                />
              </FormControl>

              { computeAge(values.dateOfBirth) < 18 ? (
                <>
                  <FormControl style={styles.field}>
                    <FormControl.Label>Responsable légal</FormControl.Label>
                    <Picker
                      style={{ minHeight: 40 }}
                      selectedValue={values.rlType}
                      onBlur={handleChange('rlType')}
                      onValueChange={(value) => { handleBlur('rlType'); saveProfileCallback({ rlType: value }); }}
                    >
                      {Object.entries(LEGAL_GUARDIAN_TYPE)
                        .map((k) => <Picker.Item label={k[1]} value={k[0]} />)}
                    </Picker>
                  </FormControl>
                  <FormControl style={styles.field}>
                    <FormControl.Label>Nom du responsable légal</FormControl.Label>
                    <Input
                      onChangeText={handleChange('rlLastName')}
                      onBlur={() => saveProfileCallback({ rlLastName: values.rlLastName })}
                      InputLeftElement={<IconButton icon="account-child" />}
                      value={values.rlLastName}
                    />
                  </FormControl>
                  <FormControl style={styles.field}>
                    <FormControl.Label>Prénom du responsable légal</FormControl.Label>
                    <Input
                      onChangeText={handleChange('rlFirstName')}
                      onBlur={() => saveProfileCallback({ rlFirstName: values.rlFirstName })}
                      InputLeftElement={<IconButton icon="account-child" />}
                      value={values.rlFirstName}
                    />
                  </FormControl>
                  <FormControl style={styles.field}>
                    <FormControl.Label>Adresse</FormControl.Label>
                    <AutocompleteLocation
                      value={values.rlAddress}
                      handleChangeText={setFieldValue}
                      handleSubmit={(value) => {
                        setFieldValue('rlAddress', value);
                        saveProfileCallback(
                          { rlAddress: value },
                        );
                      }}
                    />
                  </FormControl>
                  <FormControl style={styles.field}>
                    <FormControl.Label>Téléphone du responsable légal</FormControl.Label>
                    <Input
                      InputLeftElement={<IconButton icon="phone" />}
                      onChangeText={handleChange('rlPhone')}
                      onBlur={({ target }) => {
                        handleBlur('rlPhone');
                        if (!errors.rlPhone && target.value !== '') saveProfileCallback({ rlPhone: target.value });
                      }}
                      value={values.rlPhone}
                    />
                    {!!errors.rlPhone && <Text style={styles.error}>{errors.rlPhone}</Text>}
                  </FormControl>
                  <FormControl style={styles.field}>
                    <FormControl.Label>Email du responsable légal</FormControl.Label>
                    <Input
                      onChangeText={handleChange('rlEmail')}
                      onBlur={() => saveProfileCallback({ rlEmail: values.rlEmail })}
                      InputLeftElement={<IconButton icon="email" />}
                      value={values.rlEmail}
                    />
                  </FormControl>
                </>
              )
                : null }
            </>
          )}
        </Formik>
      </Card.Content>
    </ScrollView>
  );
}

export default InfoTab;
