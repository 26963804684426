import { gql } from '@apollo/client';

export const UpdateProfileMutation = gql`
mutation UpdateProfileForUser($bio: String, $picture: String, $address: String, $dateOfBirth: DateTime, $grade: String, $license: String, $locationOfBirth: String, $nationality: String, $phone: String, $gender: String, $rlType: String, $rlFirstName: String, $rlLastName: String, $rlEmail: String, $rlAddress: String, $rlPhone: String) {
  updateProfileForUser(bio: $bio, picture: $picture, address: $address, dateOfBirth: $dateOfBirth, grade: $grade, license: $license, locationOfBirth: $locationOfBirth, nationality: $nationality, phone: $phone, gender: $gender, rlType: $rlType, rlFirstName: $rlFirstName, rlLastName: $rlLastName, rlEmail: $rlEmail, rlAddress: $rlAddress, rlPhone: $rlPhone) {
    status
  }
}
`;

export const CreateWeightMutation = gql`
mutation CreateWeightForProfile($value: Float!, $profileId: Int) {
  createWeightForProfile(value: $value, profileId: $profileId) {
    status
    message
  }
}
`;
