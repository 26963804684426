import React from 'react';
import { ScrollView } from 'native-base';
import { Card, IconButton, List } from 'react-native-paper';

function EventParticipantsTab({ event, disabled = true }) {
  console.log(event);
  return (
    <ScrollView keyboardShouldPersistTaps="always">
      <Card.Content>
        {event?.players.length > 0
          ? event?.players.map((p) => (
            <List.Item
              title={`${p.player.firstName} ${p.player.lastName}`}
              left={(props) => (
                <IconButton
                  {...props}
                  icon={p.player.isConfirmed ? 'karate' : 'progress-clock'}
                />
              )}
            />
          ))
          : null}
      </Card.Content>
    </ScrollView>
  );
}

export default EventParticipantsTab;
