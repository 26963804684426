import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { Text, View } from 'react-native';
import {
  Input, NativeBaseProvider, Button, Icon,
} from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { useToast } from 'react-native-toast-notifications';
import { useNavigation } from '@react-navigation/native';
import { InviteMutation } from '../../gql/user/mutation';
import { styles } from '../Common.style';
import { SCREEN_NAMES } from '../../utils/constant';
import { emailSchema } from '../../utils/yupSchema';
import { AllUsersQuery } from '../../gql/user/query';

function Invite() {
  const toast = useToast();
  const navigation = useNavigation();
  const [invite, { error }] = useMutation(InviteMutation, {
    onCompleted: (data) => {
      toast.show(data?.invite?.message);
      navigation.navigate(SCREEN_NAMES.USERMANAGEMENT);
    },
    refetchQueries: [
      AllUsersQuery, // DocumentNode object parsed with gql
      'AllUsers', // Query name
    ],
  });

  if (error) toast.show(error.message);

  return (
    <View style={styles.container}>
      <View style={styles.Middle}>
        <Text style={styles.mainTitle}>Inviter un utilisateur</Text>
      </View>

      <Formik
        initialValues={{ firstName: '', lastName: '', email: '' }}
        onSubmit={(values) => invite({
          variables: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
          },
        })}
        validationSchema={emailSchema}
      >
        {({
          handleChange, handleBlur, handleSubmit, values, errors,
        }) => (
          <>
            <View style={styles.buttonStyle}>
              <View style={styles.input}>
                <Input
                  InputLeftElement={(
                    <Icon
                      as={<MaterialCommunityIcons name="karate" />}
                      size="sm"
                      m={2}
                      _light={{
                        color: 'black',
                      }}
                      _dark={{
                        color: 'gray.300',
                      }}
                    />
                  )}
                  variant="outline"
                  placeholder="Prénom"
                  _light={{
                    placeholderTextColor: 'blueGray.400',
                  }}
                  _dark={{
                    placeholderTextColor: 'blueGray.50',
                  }}
                  value={values.name}
                  onChangeText={handleChange('firstName')}
                  onBlur={handleBlur('firstName')}
                />
              </View>

              <View style={styles.input}>
                <Input
                  InputLeftElement={(
                    <Icon
                      as={<MaterialCommunityIcons name="mixed-martial-arts" />}
                      size="sm"
                      m={2}
                      _light={{
                        color: 'black',
                      }}
                      _dark={{
                        color: 'gray.300',
                      }}
                    />
                  )}
                  variant="outline"
                  placeholder="Nom"
                  _light={{
                    placeholderTextColor: 'blueGray.400',
                  }}
                  _dark={{
                    placeholderTextColor: 'blueGray.50',
                  }}
                  value={values.name}
                  onChangeText={handleChange('lastName')}
                  onBlur={handleBlur('lastName')}
                />
              </View>

              <View style={styles.input}>
                <Input
                  InputLeftElement={(
                    <Icon
                      as={<MaterialCommunityIcons name="email" />}
                      size="sm"
                      m={2}
                      _light={{
                        color: 'black',
                      }}
                      _dark={{
                        color: 'gray.300',
                      }}
                    />
                  )}
                  variant="outline"
                  placeholder="Email"
                  _light={{
                    placeholderTextColor: 'blueGray.400',
                  }}
                  _dark={{
                    placeholderTextColor: 'blueGray.50',
                  }}
                  value={values.email}
                  onChangeText={handleChange('email')}
                  onBlur={handleBlur('email')}
                />
              </View>
              {!!errors.email && <Text style={styles.error}>{errors.email}</Text>}
            </View>

            {/* Button */}
            <View style={styles.buttonStyle}>
              <Button onPress={handleSubmit} style={styles.buttonDesign}>
                Inviter
              </Button>
            </View>
          </>
        )}
      </Formik>

      <StatusBar />
    </View>
  );
}

export default function InviteScreen() {
  return (
    <NativeBaseProvider>
      <Invite />
    </NativeBaseProvider>
  );
}
