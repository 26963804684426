import React from 'react';
import { View } from 'native-base';
import { useWizard } from 'react-formik-step-wizard';

function WizardStepWrapper() {
  const { activeStep } = useWizard();

  return (
    <View
      key={activeStep.id}
      transition={{ type: 'tween', duration: 0.25 }}
    >
      <div>
        <div>
          {activeStep.component}
        </div>
      </div>
    </View>
  );
}

export default WizardStepWrapper;
