import { gql } from '@apollo/client';

export const FindManyFilesQuery = gql`
  query FindManyFiles($email: String!) {
    findManyFiles(email: $email) {
      name
      type
      id
    }
  }
`;

export const FindOneFile = gql`
  query FindOneFile($email: String!, $fileId: Int!) {
    findOneFile(email: $email, fileId: $fileId) {
      name
      type
      file
      id
    }
  }
`;
