import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { Text, View } from 'react-native';
import {
  Input, NativeBaseProvider, Button, Icon,
} from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { useToast } from 'react-native-toast-notifications';
import { useNavigation } from '@react-navigation/native';
import { SignUpOnInvite } from '../../gql/user/mutation';
import { styles } from '../Common.style';
import { emailSchema, passwordSchema } from '../../utils/yupSchema';
import { SCREEN_NAMES } from '../../utils/constant';

function Invite() {
  const nav = useNavigation();
  const [signUpOnInvite, { data, error }] = useMutation(SignUpOnInvite);
  const toast = useToast();

  if (error) toast.show(error.message);
  if (data?.signUpOnInvite?.status) {
    toast.show(data?.signUpOnInvite?.message);
    nav.navigate(SCREEN_NAMES.LOGIN);
  }

  return (
    <View style={styles.container}>
      <View style={styles.Middle}>
        <Text style={styles.mainTitle}>Finaliser l'inscription</Text>

        <Formik
          initialValues={{
            code: '',
            email: '',
            password: '',
            confirmPassword: '',
          }}
          onSubmit={(values) => signUpOnInvite({
            variables: {
              code: values.code,
              email: values.email,
              password: values.password,
            },
          })}
          validationSchema={passwordSchema.concat(emailSchema)}
        >
          {({
            handleChange, handleBlur, handleSubmit, values, errors,
          }) => (
            <>
              <View style={styles.buttonStyleX}>
                <View style={styles.input}>
                  <Input
                    InputLeftElement={(
                      <Icon
                        as={<MaterialCommunityIcons name="email" />}
                        size="sm"
                        m={2}
                        _light={{
                          color: 'black',
                        }}
                        _dark={{
                          color: 'gray.300',
                        }}
                      />
                  )}
                    variant="outline"
                    placeholder="Email"
                    _light={{
                      placeholderTextColor: 'blueGray.400',
                    }}
                    _dark={{
                      placeholderTextColor: 'blueGray.50',
                    }}
                    value={values.email}
                    onChangeText={handleChange('email')}
                    onBlur={handleBlur('email')}
                  />
                </View>
                {!!errors.email && <Text style={styles.error}>{errors.email}</Text>}
                <View style={styles.input}>
                  <Input
                    InputLeftElement={(
                      <Icon
                        as={<MaterialCommunityIcons name="form-textbox-lock" />}
                        size="sm"
                        m={2}
                        _light={{
                          color: 'black',
                        }}
                        _dark={{
                          color: 'gray.300',
                        }}
                      />
                  )}
                    variant="outline"
                    placeholder="Code reçu"
                    _light={{
                      placeholderTextColor: 'blueGray.400',
                    }}
                    _dark={{
                      placeholderTextColor: 'blueGray.50',
                    }}
                    value={values.code}
                    onChangeText={handleChange('code')}
                    onBlur={handleBlur('code')}
                  />
                </View>
                <View style={styles.input}>
                  <Input
                    InputLeftElement={(
                      <Icon
                        as={<MaterialCommunityIcons name="form-textbox-password" />}
                        size="sm"
                        m={2}
                        _light={{
                          color: 'black',
                        }}
                        _dark={{
                          color: 'gray.300',
                        }}
                      />
                  )}
                    variant="outline"
                    placeholder="Saisir un mot de passe"
                    _light={{
                      placeholderTextColor: 'blueGray.400',
                    }}
                    _dark={{
                      placeholderTextColor: 'blueGray.50',
                    }}
                    secureTextEntry
                    value={values.password}
                    onChangeText={handleChange('password')}
                    onBlur={handleBlur('password')}
                  />
                  {!!errors.password && <Text style={styles.error}>{errors.password}</Text>}
                </View>
                <View style={styles.input}>
                  <Input
                    InputLeftElement={(
                      <Icon
                        as={<MaterialCommunityIcons name="form-textbox-password" />}
                        size="sm"
                        m={2}
                        _light={{
                          color: 'black',
                        }}
                        _dark={{
                          color: 'gray.300',
                        }}
                      />
                  )}
                    variant="outline"
                    placeholder="Confirmer votre mot de passe"
                    _light={{
                      placeholderTextColor: 'blueGray.400',
                    }}
                    _dark={{
                      placeholderTextColor: 'blueGray.50',
                    }}
                    secureTextEntry
                    value={values.confirmPassword}
                    onChangeText={handleChange('confirmPassword')}
                    onBlur={handleBlur('confirmPassword')}
                  />
                  {!!errors.confirmPassword && (
                  <Text style={styles.error}>{errors.confirmPassword}</Text>
                  )}
                </View>
              </View>

              {/* Button */}
              <View style={styles.buttonStyle}>
                <Button onPress={handleSubmit} style={styles.buttonDesign}>
                  Finaliser l'inscription
                </Button>
              </View>
            </>
          )}
        </Formik>

        <StatusBar />
      </View>
    </View>
  );
}

export default function SignUpScreen() {
  return (
    <NativeBaseProvider>
      <Invite />
    </NativeBaseProvider>
  );
}
