import {
  ref, object, string, number, date,
} from 'yup';

const getCharacterValidationError = (str) => `Le mot de passe doit contenir au moins 1 caractère ${str}`;
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

const passwordSchema = object({
  password: string()
    .required('Veuillez saisir un mot de passe')
    // check minimum characters
    .min(8, 'Le mot de passe doit avoir au moins 8 caractères')
    // different error messages for different requirements
    .matches(/[0-9]/, getCharacterValidationError('numérique'))
    .matches(/[a-z]/, getCharacterValidationError('minuscule'))
    .matches(/[A-Z]/, getCharacterValidationError('majuscule')),
  confirmPassword: string()
    .required('Veuillez confirmer votre mot de passe')
    // use oneOf to match one of the values inside the array.
    // use "ref" to get the value of passwrod.
    .oneOf([ref('password')], 'Les mots de passe saisis ne sont pas identiques'),
});

const emailSchema = object({
  email: string()
    .required('Veuillez saisir une addresse mail valide')
    .email('Veuillez saisir une addresse mail valide'),
  // check minimum characters
});

const phoneSchema = object({
  phone: string()
    .matches(phoneRegExp, 'Numéro de téléphone invalide')
    .min(10, 'Numéro trop court')
    .max(10, 'Numéro trop long'),
  rlPhone: string()
    .matches(phoneRegExp, 'Numéro de téléphone invalide')
    .min(10, 'Numéro trop court')
    .max(10, 'Numéro trop long'),
});

const weightSchema = object({
  weight: number()
    .positive('Uniquement les valeurs positives sont autorisées')
    .test(
      'is-decimal',
      'Deux chiffres après la virgule maximum autorisés',
      (val) => {
        if (val !== undefined) {
          return patternTwoDigisAfterComma.test(val);
        }
        return true;
      },
    )
    .typeError('Seul un nombre est autorisé')
    .required('Valeur requise'),
});

const announcementSchema = object({
  title: string()
    .required('Veuillez saisir un titre'),
  message: string()
    .required('Veuillez saisir un message'),
  startDate: date()
    .typeError('La date de début est requise')
    .required('La date de début est requise'),
  endDate: date()
    .typeError('La date de fin est requise')
    .required('La date de fin est requise')
    .when('startDate', (startDate) => {
      if (startDate) {
        return date()
          .min(startDate, 'La date de fin doit être après la date de début')
          .typeError('La date de fin est requise');
      }
    }),
});

const eventSchema = object({
  name: string()
    .required('Veuillez saisir un nom'),
  location: string()
    .required('Veuillez saisir un lieu'),
  startAt: date()
    .required('La date de début est requise'),
  level: string()
    .required('Veuillez saisir le niveau'),
});

export {
  passwordSchema, emailSchema, phoneSchema, weightSchema, announcementSchema, eventSchema,
};
