import React, { Dimensions } from 'react-native';
import {
  NativeBaseProvider,
  ScrollView,
  useToast,
} from 'native-base';
import {
  Dialog, Portal,
} from 'react-native-paper';
import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Wizard } from 'react-formik-step-wizard';
import {
  date, object, string,
  array,
} from 'yup';
import { ModalContext } from '../../context/ModalContext';
import { CreateEvent } from '../../gql/event/mutation';
import { FormStepOne, FormStepTwo } from './StepsFormCreateEvent';
import WizardStepWrapper from './WizardStepWrapper';
import CustomFooter from './CustomFooter';
import { FindManyEvents } from '../../gql/event/query';

function Event() {
  const {
    displayCreateEventModal,
    toggleCreateEventModal,
  } = useContext(ModalContext);
  const [createEvent] = useMutation(CreateEvent);

  const toast = useToast();

  const steps = [
    {
      id: 'StepOne',
      component: <FormStepOne />,
      initialValues: {
        name: '',
        type: '',
        location: '',
        startAt: new Date(Date.now()),
        endAt: new Date(Date.now()),
        media: undefined,
      },
      validationSchema: object({
        name: string().required('Champ requis'),
        location: string().required('Champ requis'),
        type: object().required('Champ requis'),
        startAt: date()
          .required('Champ requis')
          .min(new Date(Date.now()), 'Impossible de mettre une date antérieure'),
        endAt: date()
          .typeError('La date de fin est requise')
          .required('La date de fin est requise')
          .when('startAt', (startAt) => {
            if (startAt) {
              return date()
                .min(startAt, 'La date de fin doit être après la date de début')
                .typeError('La date de fin est requise');
            }
          }),
      }),
      hidePrevious: true,
    },
    {
      id: 'StepTwo',
      component: <FormStepTwo />,
      initialValues: { level: '', categories: [] },
      validationSchema: object({
        categories: array().min(1, 'Il faut ajouter au moins une catégorie.'),
        level: object().required('Champ requis'),
      }),
      hideNext: true,
    },
  ];

  return (
    <Dialog
      visible={displayCreateEventModal}
      dismissable
      onDismiss={toggleCreateEventModal}
      style={{ maxHeight: 0.8 * Dimensions.get('window').height }}
    >
      <Dialog.ScrollArea>
        <ScrollView contentContainerStyle={{ paddingHorizontal: 24 }}>
          <Wizard
            steps={steps}
            onCompleted={async (values) => {
              await createEvent({
                variables: {
                  name: values.name,
                  location: values.location,
                  startAt: values.startAt,
                  endAt: values.endAt,
                  media: values.media,
                  level: values.level?.id,
                  type: values.type?.id,
                  categories: values.categories.map((c) => c.item),
                },
                refetchQueries: [
                  FindManyEvents, // DocumentNode object parsed with gql
                  'findManyEvents', // Query name
                ],
              });
              toggleCreateEventModal();
              toast.show('Evènement créé avec succès');
            }}
            footer={<CustomFooter />}
            wrapper={<WizardStepWrapper />}
          />
        </ScrollView>
      </Dialog.ScrollArea>
    </Dialog>
  );
}

export default function EventModal() {
  return (
    <Portal>
      <NativeBaseProvider>
        <Event />
      </NativeBaseProvider>
    </Portal>
  );
}
