import { StatusBar } from 'expo-status-bar';
import React, { useContext, useEffect } from 'react';
import {
  Text, View, TouchableOpacity, Platform,
} from 'react-native';
import {
  Input, NativeBaseProvider, Button, Icon, Box, Image,
} from 'native-base';
import { FontAwesome5 } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { useMutation } from '@apollo/client';
import { useToast } from 'react-native-toast-notifications';
import { Formik } from 'formik';
import { ProgressBar } from 'react-native-paper';
import * as Keychain from 'react-native-keychain';
import { LoginMutation } from '../../gql/user/mutation';
import { styles, ids } from '../Common.style';
import { AuthContext } from '../../context/AuthContext';
import { emailSchema } from '../../utils/yupSchema';

function Login() {
  const navigation = useNavigation();
  const authContext = useContext(AuthContext);
  const [login, { data, loading, error }] = useMutation(LoginMutation);
  const toast = useToast();

  if (error) toast.show(error.message);

  // Effect to store the token in a secure way
  useEffect(() => {
    const asyncSetToken = async () => {
      const authState = {
        email: data.login.user.email,
        accessToken: data.login.accessToken,
        refreshToken: data.login.refreshToken,
        authenticated: true,
        username: `${data.login.user.firstName} ${data.login.user.lastName}`,
        role: data.login.user.role,
      };
      if (Platform.OS === 'web') {
        localStorage.setItem('email', data.login.user.email);
        localStorage.setItem('accessToken', data.login.accessToken);
        localStorage.setItem('refreshToken', data.login.refreshToken);
        localStorage.setItem(
          'username',
          `${data.login.user.firstName} ${data.login.user.lastName}`,
        );
        localStorage.setItem('role', data.login.user.role);
        authContext.setAuthState(authState);
      } else {
        authContext.setAuthState(authState);
        await Keychain.setGenericPassword('token', JSON.stringify(data.login));
      }
    };

    if (data?.login && authContext) asyncSetToken();
  }, [data]);

  return (
    <View style={styles.container}>
      {loading && <ProgressBar />}
      <View style={styles.Middle}>
        <Box style={styles.logoStyle} dataSet={{ media: ids.logoStyle }}>
          <Image
            style={styles.responsiveImage}
            dataSet={{ media: ids.responsiveImage }}
            roundedTop="lg"
            source={require('../../assets/logo.png')}
            alt="image"
          />
        </Box>
        <Text style={styles.mainTitle}>Connexion</Text>
        <TouchableOpacity onPress={() => navigation.navigate('Signup')}>
          <Text style={styles.signupText}> J'ai un code d'invitation</Text>
        </TouchableOpacity>

        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={(values) => login({ variables: { email: values.email, password: values.password } })}
          validationSchema={emailSchema}
        >
          {({
            handleChange, handleBlur, handleSubmit, values, errors,
          }) => (
            <>
              <View style={styles.buttonStyleX}>
                <View style={styles.input}>
                  <Input
                    InputLeftElement={(
                      <Icon
                        as={<FontAwesome5 name="user-secret" />}
                        size="sm"
                        m={2}
                        _light={{
                          color: 'black',
                        }}
                        _dark={{
                          color: 'gray.300',
                        }}
                      />
                  )}
                    variant="outline"
                    placeholder="Email"
                    _light={{
                      placeholderTextColor: 'blueGray.400',
                    }}
                    _dark={{
                      placeholderTextColor: 'blueGray.50',
                    }}
                    value={values.email}
                    onChangeText={handleChange('email')}
                    onBlur={handleBlur('email')}
                  />
                </View>
                {!!errors.email && <Text style={styles.error}>{errors.email}</Text>}
              </View>

              {/* Password Input Field */}
              <View style={styles.buttonStyleX}>
                <View style={styles.input}>
                  <Input
                    InputLeftElement={(
                      <Icon
                        as={<FontAwesome5 name="key" />}
                        size="sm"
                        m={2}
                        _light={{
                          color: 'black',
                        }}
                        _dark={{
                          color: 'gray.300',
                        }}
                      />
                  )}
                    variant="outline"
                    secureTextEntry
                    placeholder="Mot de passe"
                    value={values.password}
                    onChangeText={handleChange('password')}
                    onBlur={handleBlur('password')}
                    _light={{
                      placeholderTextColor: 'blueGray.400',
                    }}
                    _dark={{
                      placeholderTextColor: 'blueGray.50',
                    }}
                  />
                </View>

                <View style={styles.text2}>
                  <TouchableOpacity onPress={() => navigation.navigate('ForgotPassword')}>
                    <Text style={styles.forgotPassword}>Mot de passe oublié ?</Text>
                  </TouchableOpacity>
                </View>
              </View>

              {/* Button */}
              <View style={styles.buttonStyle}>
                <Button onPress={handleSubmit} style={styles.buttonDesign}>
                  Se connecter
                </Button>
              </View>
            </>
          )}
        </Formik>

        <StatusBar />
      </View>
    </View>
  );
}

export default function LoginScreen() {
  return (
    <NativeBaseProvider>
      <Login />
    </NativeBaseProvider>
  );
}
