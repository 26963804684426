import React from 'react';
import { useFormikContext } from 'formik';
import { View } from 'native-base';
import { useWizard } from 'react-formik-step-wizard';
import {
  ActivityIndicator, Button, Text,
} from 'react-native-paper';
import { styles } from '../../screens/Common.style';

function CustomFooter() {
  const {
    goToPreviousStep,
    isFirstStep,
    isLoading,
    activeStep: {
      hidePrevious,
      hideNext,
      disableNext,
      disableNextOnErrors,
    },
    totalSteps,
    stepNumber,
  } = useWizard();
  const { isValid, submitForm } = useFormikContext();
  const localDisableNext = isLoading || disableNext || (disableNextOnErrors && !isValid);

  return (
    <View style={styles.buttonStyle}>
      {/* "Previous" button */}
      {!hidePrevious && (
      <div style={{ display: 'flex' }}>
        <Button
          style={{ ...styles.buttonDesign, width: '50%' }}
          onClick={goToPreviousStep}
          disabled={isFirstStep}
          icon="arrow-left-bold"
          labelStyle={{ color: 'white' }}
        >
          <Text style={{ color: 'white' }}>
            {`Précédent (${stepNumber}/${totalSteps})`}
          </Text>
        </Button>
        <Button
          style={{ ...styles.buttonDesign, width: '50%' }}
          onClick={submitForm}
          disabled={isFirstStep}
          icon="plus"
          labelStyle={{ color: 'white' }}
        >
          <Text style={{ color: 'white' }}>
            Valider
          </Text>
        </Button>
      </div>
      )}
      {/* "Next" button */}
      {!hideNext && (
      // Still possible to trigger submit even though button is disabled.
      // Main reason is to display validation errors.
      <Button
        style={styles.buttonDesign}
        onPress={!localDisableNext ? submitForm : undefined}
        icon="arrow-right-bold"
        labelStyle={{ color: 'white' }}
      >
        {isLoading && (
          <ActivityIndicator />
        )}
        {!isLoading && (
          <Text style={{ color: 'white' }}>
            {`Suivant (${stepNumber}/${totalSteps})`}
          </Text>
        )}
      </Button>
      )}
    </View>
  );
}

export default CustomFooter;
