import React from 'react-native';
import { Formik } from 'formik';
import {
  FormControl, Input, NativeBaseProvider, View,
} from 'native-base';
import {
  Button, Dialog, IconButton, Portal, ProgressBar, Text,
} from 'react-native-paper';
import {
  Grid, LineChart, XAxis, YAxis,
} from 'react-native-svg-charts';
import { useMutation, useQuery } from '@apollo/client';
import * as scale from 'd3-scale';
import { useContext } from 'react';
import { CreateWeightMutation } from '../../gql/profile/mutation';
import { weightSchema } from '../../utils/yupSchema';
import { FindMyWeightsOnProfile, FindWeightsOnOneProfile } from '../../gql/profile/query';
import { styles } from '../../screens/Common.style';
import { ModalContext } from '../../context/ModalContext';
import { FindOneUserQuery } from '../../gql/user/query';

const axesSvg = { fontSize: 10, fill: 'grey' };
const verticalContentInset = { top: 10, bottom: 10 };
const xAxisHeight = 60;

function WeightTracker() {
  const { displayWeightModal, toggleWeightModal } = useContext(ModalContext);
  const [createWeightForProfile] = useMutation(CreateWeightMutation);
  const { data, loading } = useQuery(
    FindWeightsOnOneProfile,
    {
      variables: { profileId: Number(displayWeightModal?.profileId) },
      skip: !displayWeightModal?.profileId,
    },
  );
  const { data: myData, loading: myLoading } = useQuery(
    FindMyWeightsOnProfile,
    {
      skip: displayWeightModal?.profileId !== undefined,
    },
  );

  const profile = displayWeightModal?.profileId ? data?.findOneProfile : myData?.findMyProfile;

  return loading || myLoading ? (
    <ProgressBar />
  ) : profile && (
  <Formik
    initialValues={{
      weight: profile.weights?.length > 0
        ? profile.weights[profile.weights?.length - 1].value
        : 0,
    }}
    validateOnChange
    validationSchema={weightSchema}
    onSubmit={async (values) => {
      await createWeightForProfile({
        variables: {
          value: Number.parseFloat(values.weight),
          profileId: profile.id,
        },
        refetchQueries: [
          FindOneUserQuery, // DocumentNode object parsed with gql
          'findOneUser', // Query name
        ],
      });
      toggleWeightModal();
    }}
  >
    {({
      handleChange, handleSubmit, values, errors,
    }) => (
      <Dialog visible={displayWeightModal.display} dismissable onDismiss={toggleWeightModal}>
        <Dialog.Title>Suivi du poids</Dialog.Title>
        <Dialog.Content>
          {profile.weights?.length > 0 ? (
            <View style={{ height: 200, flexDirection: 'row' }}>
              <YAxis
                data={profile.weights}
                yAccessor={({ item }) => item.value}
                style={{ marginBottom: xAxisHeight }}
                contentInset={verticalContentInset}
                numberOfTicks={6}
                svg={axesSvg}
              />
              <View style={{ flex: 1, marginLeft: 10 }}>
                <LineChart
                  style={{ flex: 1 }}
                  data={profile.weights}
                  xAccessor={({ item }) => new Date(item.createdAt).valueOf()}
                  yAccessor={({ item }) => item.value}
                  scale={scale.scaleTime}
                  contentInset={verticalContentInset}
                  svg={{ stroke: 'rgb(134, 65, 244)' }}
                >
                  <Grid />
                </LineChart>
                <XAxis
                  style={{ height: xAxisHeight }}
                  data={profile.weights}
                  scale={scale.scaleTime}
                  xAccessor={({ item }) => new Date(item.createdAt).valueOf()}
                  formatLabel={(value) => new Date(value).toLocaleDateString('fr-FR', { year: '2-digit', month: 'numeric', day: 'numeric' })}
                  contentInset={{ left: 20, right: 20 }}
                  svg={{
                    translateX: 0, translateY: 20, fontSize: 10, fill: 'grey', rotation: -45,
                  }}
                />
              </View>
            </View>
          ) : null }
          <FormControl style={[styles.field]}>
            <FormControl.Label>Poids</FormControl.Label>
            <Input
              onChangeText={handleChange('weight')}
              InputLeftElement={<IconButton icon="weight" />}
              InputRightElement={<Text>kg</Text>}
              value={values.weight}
            />
          </FormControl>
          {!!errors.weight && <Text style={styles.error}>{errors.weight}</Text>}
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={handleSubmit}>
            Envoyer
          </Button>
        </Dialog.Actions>
      </Dialog>
    )}
  </Formik>
  );
}

export default function WeightTrackerModal() {
  return (
    <Portal>
      <NativeBaseProvider>
        <WeightTracker />
      </NativeBaseProvider>
    </Portal>
  );
}
